import { Collapse, CollapseProps, Flex, theme, Typography } from 'antd';
import React, { CSSProperties } from 'react';

import { CaretRightOutlined } from '@ant-design/icons';
import { Link } from '@pankod/refine-react-router-v6';

const getItems: (panelStyle: CSSProperties) => CollapseProps['items'] = (
  panelStyle,
) => [
  {
    key: '1',
    label: '"Экспедитор" - как он работает ?',
    children: (
      <Flex>
        <iframe
          width="100%"
          height="315"
          src={'https://www.youtube.com/embed/50d3hsRl_7c'}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Flex>
    ),
    style: panelStyle,
  },
  {
    key: '2',
    label: 'Какие виды перевозок бывают ?',
    children: (
      <Flex>
        <iframe
          width="100%"
          height="315"
          src={'https://www.youtube.com/embed/uy-tM602-LA'}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Flex>
    ),
    style: panelStyle,
  },
  {
    key: '3',
    label: 'Размеры грузовых машин',
    children: (
      <Flex>
        <iframe
          width="100%"
          height="315"
          src={'https://www.youtube.com/embed/N507uXITgGM'}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Flex>
    ),
    style: panelStyle,
  },
  {
    key: '4',
    label: 'Логистика, типы транспорта',
    children: (
      <Flex>
        <iframe
          width="100%"
          height="315"
          src={'https://www.youtube.com/embed/gh42uBdxF8'}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Flex>
    ),
    style: panelStyle,
  },
  {
    key: '5',
    label: 'Как работает РЕФ ?  ',
    children: (
      <Flex>
        <iframe
          width="100%"
          height="315"
          src={'https://www.youtube.com/embed/m4v5kUrPlT4'}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Flex>
    ),
    style: panelStyle,
  },
  {
    key: '6',
    label: 'Загрузка фуры за 5 минут',
    children: (
      <Flex>
        <iframe
          width="100%"
          height="315"
          src={'https://www.youtube.com/embed/grhPq_W72EY'}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Flex>
    ),
    style: panelStyle,
  },
  {
    key: '7',
    label: 'Погрузка яблок на фуру',
    children: (
      <Flex>
        <iframe
          width="100%"
          height="315"
          src={'https://www.youtube.com/embed/VUCm84ojkGo'}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Flex>
    ),
    style: panelStyle,
  },
  {
    key: '8',
    label: 'Погрузка и доставка груза',
    children: (
      <Flex>
        <iframe
          width="100%"
          height="315"
          src={'https://www.youtube.com/embed/UlpQN6iRDsY'}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Flex>
    ),
    style: panelStyle,
  },
  {
    key: '9',
    label: 'Погрузка фуры',
    children: (
      <Flex>
        <iframe
          width="100%"
          height="315"
          src={'https://www.youtube.com/embed/RP7oOKq0QAs'}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Flex>
    ),
    style: panelStyle,
  },
  {
    key: '10',
    label: 'Выгрузка зерна',
    children: (
      <Flex>
        <iframe
          width="100%"
          height="315"
          src={'https://www.youtube.com/embed/Sx2QGio2Aic'}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Flex>
    ),
    style: panelStyle,
  },
  {
    key: '11',
    label: 'Как правильно загрузить и выгрузить ?',
    children: (
      <Flex>
        <iframe
          width="100%"
          height="315"
          src={'https://www.youtube.com/embed/m5zesX9LEzw'}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Flex>
    ),
    style: panelStyle,
  },
  {
    key: '12',
    label: 'Сколько тонн груза может перевозить фура ?',
    children: (
      <Flex>
        <iframe
          width="100%"
          height="315"
          src={'https://www.youtube.com/embed/dzcOVzPszHE'}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Flex>
    ),
    style: panelStyle,
  },
  {
    key: '14',
    label: 'Типы паллет',
    children: (
      <Flex vertical>
        <Link to={'https://www.youtube.com/embed/jiGlj6DrPcY'}>
          Ссылка на ютуб
        </Link>
        <iframe
          width="100%"
          height="315"
          src={'https://www.youtube.com/embed/jiGlj6DrPcY'}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Flex>
    ),
    style: panelStyle,
  },
  {
    key: '15',
    label: 'Паллеты и поддоны',
    children: (
      <Flex vertical>
        <iframe
          width="100%"
          height="315"
          src={'https://www.youtube.com/embed/Ctpo7Gj60Sw'}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Flex>
    ),
    style: panelStyle,
  },
];

const SecondLesson = () => {
  const { token } = theme.useToken();

  const panelStyle: React.CSSProperties = {
    marginBottom: 24,
    background: token.colorBgContainer,
    borderRadius: token.borderRadiusLG,
  };
  return (
    <Flex vertical style={{ maxWidth: 1000 }}>
      <Typography.Title level={4}>Урок второй</Typography.Title>
      <Typography.Title level={5}>
        Мы продолжаем знакомство с профессией ЛОГИСТ. В этом уроке мы
        ознакомимся с транспортными средствами, способы погрузок и разновидности
        паллет (поддонов).
      </Typography.Title>
      <Collapse
        style={{ background: 'transparent' }}
        bordered={false}
        defaultActiveKey={['1']}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
        items={getItems(panelStyle)}
      />
      <Typography.Title level={5}>
        Если вы просмотрели видео-обучение из этого урока , можете перейти к
        уроку №3
      </Typography.Title>
    </Flex>
  );
};

export default SecondLesson;
