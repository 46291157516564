import { Refine } from '@pankod/refine-core';
import { ReadyPage } from '@pankod/refine-antd';
import Icon, {
  UserOutlined,
  SolutionOutlined,
  DashboardOutlined,
  DiffOutlined,
  BankOutlined,
  CarOutlined,
  FileDoneOutlined,
  CalendarOutlined,
  CarryOutOutlined,
  DockerOutlined,
  FileProtectOutlined,
  DatabaseOutlined,
  WalletOutlined,
  DingdingOutlined,
  ContainerOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import { useEffect, useContext } from 'react';
import '@pankod/refine-antd/dist/reset.css';
import routerProvider from '@pankod/refine-react-router-v6';
import nestjsxCrudDataProvider from '@pankod/refine-nestjsx-crud';
import { UserList, UserCreate, UserEdit, UserShow } from 'pages/users';
import {
  Title,
  Header,
  Sider,
  Footer,
  Layout,
  OffLayoutArea,
} from 'components/layout';
import { Login } from 'pages/login';
import { Recover } from 'pages/recover';
import { notificationProvider } from '@pankod/refine-antd';
import { useTranslation } from 'react-i18next';
import { accessControlProvider, authProvider } from './access.control.provider';
import axios from 'axios';
import { ErrorPage } from './errorPage';
import { liveProvider, Ably } from '@pankod/refine-ably';
import { UserProvider, UserContext } from 'UserProvider';
import { useMemo } from 'react';
import { CompaniesList } from 'pages/lenders';
import { DriversList } from 'pages/drivers';
import { Dashboard } from 'pages/dashboard/show';
import { OfferCreate, OfferEdit, OffersList } from 'pages/offers';
import { CalendarList } from 'pages/calendar';
import { FirmPayList } from 'pages/payFirm';
import { TransportPayList } from 'pages/payTransport';
import { VamaList, VamaShipsList } from 'pages/vama';
import { OfferShow } from 'pages/offers/show';
const ablyClient = new Ably.Realtime(
  'QlDA9A.w2U1DA:MWmdo1fplyf2q0UkG5cRzSYAGNYdGCqAv50Khz9Ccl8',
);

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { AssigList } from 'pages/assig/list';
import { TickedShow } from 'pages/ticked';
import { InvoiceShow } from 'pages/invoice';
import { ActShow } from 'pages/act';
import { CashList } from 'pages/cash';
import { ShipsCreate, ShipsEdit, ShipsList } from 'pages/ships';
import { SendersList } from 'pages/senders';
import { ShipsShow } from 'pages/ships/show';
import { Education } from 'pages/edications';

export const axiosInstance = axios.create();
function App() {
  const userContext = useContext(UserContext);

  dayjs.extend(utc);
  dayjs.extend(timezone);

  dayjs.tz.setDefault('Europe/Moscow');

  useEffect(() => {
    if (userContext) {
      window.setUserFromOutside = userContext.setUserFromOutside;
    }
  }, [userContext]);

  const { t, i18n } = useTranslation();
  const i18nProvider = {
    translate: (key: string, params: Record<string, unknown>) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };
  const { RouterComponent } = routerProvider;
  const API_URL = process.env.REACT_APP_BACKEND_URL;
  let dataProvider: any;
  if (API_URL && axiosInstance) {
    dataProvider = nestjsxCrudDataProvider(API_URL + '/api/v1', axiosInstance);
  } else {
    dataProvider = nestjsxCrudDataProvider('http://localhost:3001');
  }

  const CustomRouterComponent = () => <RouterComponent />;

  const MemoizedRefine = useMemo(
    () => (
      <UserProvider>
        <Refine
          accessControlProvider={accessControlProvider}
          notificationProvider={notificationProvider}
          liveProvider={liveProvider(ablyClient)}
          options={{ liveMode: 'auto' }}
          ReadyPage={ReadyPage}
          catchAll={<ErrorPage />}
          routerProvider={{
            ...routerProvider,
            RouterComponent: CustomRouterComponent,
            routes: [
              {
                element: <Recover />,
                path: '/recover',
                layout: true,
              },
            ],
          }}
          dataProvider={dataProvider}
          authProvider={authProvider}
          LoginPage={Login}
          i18nProvider={i18nProvider}
          resources={[
            {
              name: 'dashboard',
              options: {
                label: t('dashboard.title'),
              },
              list: Dashboard,
              icon: <DashboardOutlined />,
            },
            {
              name: 'blanks',
              options: {
                label: t('offers.title'),
              },
              icon: <DatabaseOutlined />,
            },
            {
              name: 'offers',
              options: {
                label: t('offers.expedition.title'),
              },
              list: OffersList,
              create: OfferCreate,
              show: OfferShow,
              edit: OfferEdit,
              icon: <DiffOutlined />,
              parentName: 'blanks',
            },

            {
              name: 'assig',
              options: {
                label: t('offers.assig.title'),
              },

              list: OffersList,
              icon: <FileProtectOutlined />,
              parentName: 'blanks',
            },
            // {
            //   name: 'trains',
            //   options: {
            //     label: t('offers.train.title'),
            //   },
            //   list: OffersList,
            //   // create: OfferCreate,
            //   // edit: OffersList,
            //   icon: <DiffOutlined />,
            //   parentName: 'blanks',
            // },
            {
              name: 'ships',
              options: {
                label: t('offers.ships.title'),
              },
              list: ShipsList,
              create: ShipsCreate,
              edit: ShipsEdit,
              show: ShipsShow,
              icon: <DockerOutlined />,
              parentName: 'blanks',
            },
            // {
            //   name: 'airlines',
            //   options: {
            //     label: t('offers.airlines.title'),
            //   },
            //   list: OffersList,
            //   icon: <DingdingOutlined />,
            //   parentName: 'blanks',
            // },
            {
              name: 'company',
              options: {
                label: t('companies.title'),
              },
              icon: <BankOutlined />,
            },

            {
              name: 'firms',
              options: {
                label: t('companies.lender.title'),
              },
              list: CompaniesList,
              icon: <BankOutlined />,
              parentName: 'company',
            },
            {
              name: 'drivers',
              options: {
                label: t('companies.drivers.title'),
              },
              list: DriversList,
              icon: <CarOutlined />,
              parentName: 'company',
            },
            {
              name: 'sender',
              options: {
                label: t('companies.sender.title'),
              },
              list: SendersList,
              icon: <ContainerOutlined />,
              parentName: 'company',
            },

            {
              name: 'vama',
              options: {
                label: t('vama.title'),
              },
              icon: <SolutionOutlined />,
              key: 'vama',
            },
            {
              name: 'vama_expedition',
              options: {
                label: t('offers.expedition.title'),
              },
              list: VamaList,
              icon: <SolutionOutlined />,
              parentName: 'vama',
            },
            {
              name: 'vama_ships',
              options: {
                label: t('offers.ships.title'),
              },
              list: VamaShipsList,
              icon: <SolutionOutlined />,
              parentName: 'vama',
            },
            {
              name: 'payments',
              options: {
                label: t('payments.title'),
              },
              icon: <WalletOutlined />,
              key: 'payments',
            },
            {
              name: 'payment_lender',
              options: {
                label: t('payments.lender.title'),
              },
              list: FirmPayList,
              icon: <FileDoneOutlined />,
              parentName: 'payments',
            },
            {
              name: 'payment_driver',
              options: {
                label: t('payments.driver.title'),
              },
              list: TransportPayList,
              icon: <FileDoneOutlined />,
              parentName: 'payments',
            },
            {
              name: 'calendars',
              options: {
                label: t('calendars.title'),
              },
              icon: <CalendarOutlined />,
              key: 'calendars',
            },
            {
              name: 'calendar',
              options: {
                label: t('calendar.logistic'),
              },
              list: CalendarList,
              icon: <CalendarOutlined />,
              parentName: 'calendars',
            },
            {
              name: 'assig',
              options: {
                label: t('calendar.assig'),
              },
              list: AssigList,
              icon: <CalendarOutlined />,
              parentName: 'calendars',
            },
            {
              name: 'cash',
              options: {
                label: t('cash.title'),
              },
              list: CashList,
              icon: <CarryOutOutlined />,
            },
            {
              name: 'users',
              options: {
                label: t('users.title'),
              },
              list: UserList,
              create: UserCreate,
              edit: UserEdit,
              show: UserShow,
              icon: <UserOutlined />,
            },
            {
              name: 'ticket',
              show: TickedShow,
            },
            {
              name: 'invoice',
              show: InvoiceShow,
            },
            {
              name: 'education',
              list: Education,
              icon: <InfoCircleOutlined />,

              options: {
                label: t('education.title'),
              },
            },
            {
              name: 'act',
              show: ActShow,
            },
          ]}
          Title={Title}
          Header={Header}
          Sider={Sider}
          Footer={Footer}
          Layout={Layout}
          OffLayoutArea={OffLayoutArea}
        />
      </UserProvider>
    ),
    [],
  );

  return MemoizedRefine;
}

export default App;
