import { Collapse, CollapseProps, Flex, theme, Typography } from 'antd';
import React, { CSSProperties } from 'react';
import { CaretRightOutlined } from '@ant-design/icons';
import { Link } from '@pankod/refine-react-router-v6';

const getItems: (panelStyle: CSSProperties) => CollapseProps['items'] = (
  panelStyle,
) => [
  {
    key: '1',
    label: 'Реальные звонки. Примеры',
    children: (
      <Flex>
        <iframe
          width="100%"
          height="315"
          src={'https://www.youtube.com/embed/TolZuzlrW_0'}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Flex>
    ),
    style: panelStyle,
  },
  {
    key: '2',
    label: 'Реальные звонки. Работа с возражениями',
    children: (
      <Flex>
        <iframe
          width="100%"
          height="315"
          src={'https://www.youtube.com/embed/LgcTq8kSxiA'}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Flex>
    ),
    style: panelStyle,
  },
  {
    key: '3',
    label: 'Реальные звонки. Так нельзя продавать',
    children: (
      <Flex>
        <iframe
          width="100%"
          height="315"
          src={'https://www.youtube.com/embed/BjBuMMiegLQ'}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Flex>
    ),
    style: panelStyle,
  },
  {
    key: '4',
    label: 'Пример холодного звонка',
    children: (
      <Flex>
        <iframe
          width="100%"
          height="315"
          src={'https://www.youtube.com/embed/-9sEdu7dw_k'}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Flex>
    ),
    style: panelStyle,
  },
  {
    key: '5',
    label: 'Страх холодного звонка. Как избавиться?',
    children: (
      <Flex>
        <iframe
          width="100%"
          height="315"
          src={'https://www.youtube.com/embed/Lsw1WAAAYlM'}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Flex>
    ),
    style: panelStyle,
  },
  {
    key: '6',
    label: 'Работа над возражением : "ничего не надо!"',
    children: (
      <Flex>
        <iframe
          width="100%"
          height="315"
          src={'https://www.youtube.com/embed/JVnyXHW2AOw'}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Flex>
    ),
    style: panelStyle,
  },
  {
    key: '7',
    label: 'Продающие фразы',
    children: (
      <Flex>
        <iframe
          width="100%"
          height="315"
          src={'https://www.youtube.com/embed/p0pEdmB5ObM'}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Flex>
    ),
    style: panelStyle,
  },
  {
    key: '8',
    label: 'Возражение "Работаю с другими" ',
    children: (
      <Flex>
        <iframe
          width="100%"
          height="315"
          src={'https://www.youtube.com/embed/T8aBfbu-Kpw'}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Flex>
    ),
    style: panelStyle,
  },
  {
    key: '9',
    label: '7 примеров работы с возражениями',
    children: (
      <Flex>
        <iframe
          width="100%"
          height="315"
          src={'https://www.youtube.com/embed/b9EMLC29fCw'}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Flex>
    ),
    style: panelStyle,
  },
  {
    key: '10',
    label: 'Возражежние "Дорого"',
    children: (
      <Flex>
        <iframe
          width="100%"
          height="315"
          src={'https://www.youtube.com/embed/1p65go_i-h8'}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Flex>
    ),
    style: panelStyle,
  },
  {
    key: '11',
    label: 'Возражение "НЕ НАДО! НЕ ИНТЕРЕСУЕТ!"',
    children: (
      <Flex>
        <iframe
          width="100%"
          height="315"
          src={'https://www.youtube.com/embed/-QOap8hEtpo'}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Flex>
    ),
    style: panelStyle,
  },
  {
    key: '12',
    label: '"Я ПОДУМАЮ" - как с ним бороться ?',
    children: (
      <Flex>
        <iframe
          width="100%"
          height="315"
          src={'https://www.youtube.com/embed/xXTCkGduJHk'}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Flex>
    ),
    style: panelStyle,
  },
  {
    key: '14',
    label: 'Работа с возражениями в продажах',
    children: (
      <Flex vertical>
        <iframe
          width="100%"
          height="315"
          src={'https://www.youtube.com/embed/xbmOladP5Jg'}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Flex>
    ),
    style: panelStyle,
  },
  {
    key: '15',
    label: '10 лучших техник продаж',
    children: (
      <Flex vertical>
        <iframe
          width="100%"
          height="315"
          src={'https://www.youtube.com/embed/M6EUOQTPg24'}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Flex>
    ),
    style: panelStyle,
  },
  {
    key: '16',
    label: 'Скрипты холодных продаж',
    children: (
      <Flex vertical>
        <iframe
          width="100%"
          height="315"
          src={'https://www.youtube.com/embed/IizLFRYEvfM'}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Flex>
    ),
    style: panelStyle,
  },
  {
    key: '17',
    label: 'Холодные звонки',
    children: (
      <Flex vertical>
        <iframe
          width="100%"
          height="315"
          src={'https://www.youtube.com/embed/t_Urg7RZwdU'}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Flex>
    ),
    style: panelStyle,
  },
  {
    key: '18',
    label: 'Искусство продаж',
    children: (
      <Flex vertical>
        <iframe
          width="100%"
          height="315"
          src={'https://www.youtube.com/embed/oX2soG_3mh4'}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Flex>
    ),
    style: panelStyle,
  },
  {
    key: '19',
    label: 'Грамотный холодный звонок',
    children: (
      <Flex vertical>
        <iframe
          width="100%"
          height="315"
          src={'https://www.youtube.com/embed/37uvvCWGWo0'}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Flex>
    ),
    style: panelStyle,
  },
  {
    key: '20',
    label: 'Крутой холодный звонок',
    children: (
      <Flex vertical>
        <iframe
          width="100%"
          height="315"
          src={'https://www.youtube.com/embed/dN3pYonYaOU'}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Flex>
    ),
    style: panelStyle,
  },
  {
    key: '21',
    label: 'Холодный звонок - высший пилотаж',
    children: (
      <Flex vertical>
        <iframe
          width="100%"
          height="315"
          src={'https://www.youtube.com/embed/k-uI3nwFT7o'}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Flex>
    ),
    style: panelStyle,
  },
  {
    key: '22',
    label: 'Разбор холодного звонка',
    children: (
      <Flex vertical>
        <iframe
          width="100%"
          height="315"
          src={'https://www.youtube.com/embed/QGv_UzW1SRo'}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Flex>
    ),
    style: panelStyle,
  },
  {
    key: '23',
    label: 'Идеальная самопрезентация',
    children: (
      <Flex vertical>
        <iframe
          width="100%"
          height="315"
          src={'https://www.youtube.com/embed/FBiUxoq64mc'}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Flex>
    ),
    style: panelStyle,
  },
];
const FourLesson = () => {
  const { token } = theme.useToken();

  const panelStyle: React.CSSProperties = {
    marginBottom: 24,
    background: token.colorBgContainer,
    borderRadius: token.borderRadiusLG,
  };

  return (
    <Flex vertical style={{ maxWidth: 1000 }}>
      <Typography.Title level={4}>
        Урок четвертый. Холодные звонки и др.
      </Typography.Title>
      <Typography.Title level={5}>
        Холодные звонки, проход секретаря, работа на возражение и работа по
        скриптам. Техника и искусство продаж.
      </Typography.Title>
      <Collapse
        style={{ background: 'transparent' }}
        bordered={false}
        defaultActiveKey={['1']}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
        items={getItems(panelStyle)}
      />
      <Typography.Title level={5}>
        Если вы просмотрели видео-обучение из этого урока , можете перейти к
        обучению в системе GEOTRANS
      </Typography.Title>
    </Flex>
  );
};

export default FourLesson;
