import {
  Button,
  Col,
  Divider,
  EditButton,
  Progress,
  Row,
  ShowButton,
  Space,
  Spin,
  Tooltip,
  Typography,
} from '@pankod/refine-antd';
import {
  CanAccess,
  IResourceComponentsProps,
  useGetLocale,
  useNavigation,
  useSetLocale,
  useShow,
  useTranslate,
} from '@pankod/refine-core';
import { useParams } from '@pankod/refine-react-router-v6';
import { UserContext } from 'UserProvider';
import { ClientType } from 'enums/client.type';
import { Firms } from 'enums/firms.type';
import { Languages } from 'enums/languages.type';
import { Role } from 'enums/role';
import { ShippingType } from 'enums/shipping.type';
import { ICompanies, IOffer, IShip } from 'interfaces';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const checkFirm = (firm: Firms) => {
  switch (firm) {
    case Firms.RCI:
      return 'rci';
    case Firms.REAL:
      return 'real';
    case Firms.RCI_INVEST:
      return 'invest';
    case Firms.RCI_PMR:
      return 'rci_pmr';
    default:
      return 'real';
  }
};

export const ShipsShow: React.FC<IResourceComponentsProps> = () => {
  const { id } = useParams();
  const ids = id?.split('&')[0];

  const userContext = useContext(UserContext);
  const me = userContext ? userContext.user : null;
  const [visibleStamp, setVisibleStamp] = useState<boolean>(
    me?.role === Role.ACCOUNTANT ? true : false,
  );

  const [hideBtn, setHideBtn] = useState<boolean>(true);

  const {
    queryResult: { data: response, isLoading, refetch: refetchOffer },
  } = useShow<IShip>({ resource: 'ships', id: ids });

  const { t } = useTranslation();

  // useEffect(() => {
  //   if (response?.data) {
  //     if (response?.data.language === Languages.RU) {
  //       console.log('ru', response?.data.language);
  //       changeLanguage('ru');
  //       // window.location.reload();
  //     } else {
  //       console.log('ro', response?.data.language);
  //       changeLanguage('ro');
  //       // window.location.reload();
  //     }
  //   }
  // }, [response]);
  if (!response) {
    return <Spin>Загрузка</Spin>;
  }
  const { data } = response;

  const leftSide = [
    {
      title: 'ships.typeData',
      data: [
        { title: 'ships.type', text: `${data.typeShipping}` },
        { title: 'ships.conditions', text: `${data.condition}` },
        {
          title: 'ships.pol',
          text: `${data.pol}`,
        },
        {
          title: 'ships.pod',
          text: `${data.pod}`,
        },
      ],
    },
    {
      title: 'ships.cargoInfo',
      data: [
        {
          title:
            data.typeShipping === ShippingType.FCL
              ? 'ships.container'
              : 'ships.volume',
          text: `${
            data.typeShipping === ShippingType.FCL
              ? data.containerType
              : data.containerVolume
          }`,
        },
        { title: 'ships.mass', text: `${data.mass}` },
        { title: 'ships.characterCargo', text: `${data.characterCargo}` },
        { title: 'ships.adr', text: `${data.adr}` },
      ],
    },
    {
      title: 'ships.condition',
      data: [
        {
          title: 'ships.payment',
          text: `Договор №${data.oferta ? data.oferta : ''}`,
        },
        {
          title: 'ships.paymentADS',
          text: `${data.condition}${
            data.aditionalPrice
              ? ` - ${Number(data.aditionalPrice).toLocaleString('ru-RU', {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })} USD`
              : ''
          }`,
        },
        {
          title: 'ships.paymentShip',
          text: `${Number(data.priceSell).toLocaleString('ru-RU', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })} USD`,
        },
        {
          title: 'ships.paymentFirm',
          text: `${(+data.priceSell + +data.aditionalPrice).toLocaleString(
            'ru-RU',
            {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            },
          )} USD`,
        },
        {
          title: 'ships.contactRCI',
          text: `${data.manager.lastName} ${data.manager.firstName} ${data.manager.phone}`,
        },
      ],
    },
  ];

  const cancelEditPage = () => {
    document.designMode = 'off';
    setHideBtn(true);
  };
  const editPage = () => {
    document.designMode = 'on';
    setHideBtn(false);
  };

  function save(divName: any) {
    const printContents = document.getElementById(divName)?.innerHTML;
    const originalContents = document.body.innerHTML;
    const popupWin = window.open('', '_blank');
    if (divName && printContents && popupWin) {
      popupWin.document.open();
      popupWin.document.write(
        `<html><head ><title>Заявка №${data.offerNumber}</title><style type="text/css">*{box-sizing:border-box;line-height:1rem;font-size:16px; -webkit-print-color-adjust: exact;} td{font-size: 10px} </style></head><body style="box-sizing: border-box;font-family: sans-serif;margin: 0;padding: 0;position: relative">` +
          printContents +
          '</body></html>',
      );
      popupWin.document.close();
      popupWin.print();
      // popupWin.onafterprint = function () {
      //   popupWin.close();
      // };
    }
  }

  return !isLoading && data ? (
    <CanAccess resource="offers" action="show">
      <div
        style={{
          overflow: 'auto',
          paddingBottom: '70px',
        }}
      >
        <>
          <Row gutter={[20, 20]}>
            <Col>
              <Space size={12}>
                <Button type="primary" onClick={() => save('printable')}>
                  Скачать заявку
                </Button>
                {/* <Button
                  type="primary"
                  onClick={() =>
                    offerType === ClientType.FIRM
                      ? setOfferType(ClientType.TRANSPORT)
                      : setOfferType(ClientType.FIRM)
                  }
                >
                  Заявка для{' '}
                  {offerType === ClientType.FIRM
                    ? ClientType.TRANSPORT
                    : ClientType.FIRM}
                </Button> */}
                {/* {hideBtn ? (
                  <Tooltip title={t('can_edit_only_this_page')}>
                    <Button type="primary" onClick={editPage}>
                      Править
                    </Button>
                  </Tooltip>
                ) : (
                  <Tooltip title={t('cancel_edit_mode')}>
                    <Button type="primary" danger onClick={cancelEditPage}>
                      Выключить режим правок
                    </Button>
                  </Tooltip>
                )} */}
                <CanAccess resource="ships" action="edit">
                  <EditButton
                    type="primary"
                    recordItemId={data.uuid}
                    //   style={{ color: '#fff', '&:hover': { color: '#fff' } }}
                  >
                    Изменить
                  </EditButton>
                </CanAccess>
                <CanAccess resource="offers" action="stamp">
                  {!visibleStamp ? (
                    <Tooltip title={t('can_stamp_only_this_page')}>
                      <Button
                        type="primary"
                        onClick={() => setVisibleStamp(true)}
                      >
                        Показать печать
                      </Button>
                    </Tooltip>
                  ) : (
                    <Tooltip title={t('cancel_stamp_only_this_page')}>
                      <Button
                        type="primary"
                        danger
                        onClick={() => setVisibleStamp(false)}
                      >
                        Скрыть печать
                      </Button>
                    </Tooltip>
                  )}
                </CanAccess>
              </Space>
            </Col>

            <Col
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                width: '100%',
              }}
            >
              {isLoading ? (
                <div
                  style={{
                    width: '100%',
                    height: '80vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Progress size={180} />
                </div>
              ) : (
                <div className="printable" id="printable">
                  <div
                    style={{
                      width: '21cm',
                      maxWidth: '21cm',
                      minHeight: '28.9cm',
                      maxHeight: '28.9cm',
                      backgroundColor: '#ffffff',
                      padding: '0.2cm 0.7cm',
                      display: 'flex',
                      position: 'relative',

                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      margin: 'auto',
                      marginBottom: '10px',
                    }}
                  >
                    <div
                      style={{
                        position: 'relative',
                        height: '100%',
                        gap: '12px',
                      }}
                    >
                      <div style={{ marginBottom: '16px' }}>
                        <img
                          src={`${
                            process.env.PUBLIC_URL
                          }/img/ico_logo.${checkFirm(data.company)}.png`}
                          alt="Real Cargo Logo"
                          style={{ width: '300px' }}
                        />
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          marginBottom: '16px',
                        }}
                      >
                        <Typography.Text
                          style={{ fontSize: '20px', fontWeight: 600 }}
                        >
                          {t('ships.mainLabel', {
                            lng: data.language === Languages.RU ? 'ru' : 'ro',
                          })}
                          {` - ${
                            data.typeShipping === ShippingType.FCL
                              ? 'FCL'
                              : 'LCL'
                          } №${data?.uuid} / ${data.rs}`}
                        </Typography.Text>
                      </div>

                      <div>
                        <div style={{ display: 'flex', gap: '12px' }}>
                          <div
                            style={{
                              display: 'flex',
                              flex: 1,
                              flexDirection: 'column',
                            }}
                          >
                            <div
                              style={{
                                borderBottom: '1px solid #e6e6e6',
                                flexWrap: 'nowrap',
                                display: 'flex',
                                marginBottom: 12,
                              }}
                            >
                              <Typography style={{ color: '#848484' }}>
                                {t('ships.for', {
                                  lng:
                                    data.language === Languages.RU
                                      ? 'ru'
                                      : 'ro',
                                })}
                              </Typography>
                              <Typography
                                style={{ color: '#101010', margin: '0 auto' }}
                              >
                                {data.client.orgName}
                              </Typography>
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                              }}
                            >
                              {leftSide.map(
                                (
                                  el: {
                                    title: string;
                                    data: { title: string; text: string }[];
                                  },
                                  id,
                                ) => {
                                  return (
                                    <div key={id}>
                                      <div
                                        style={{
                                          border: '1px solid #cfcfcf',
                                          borderTop: 0,
                                          borderRadius: '5px',
                                          overflow: 'hidden',
                                          marginBottom: '12px',
                                        }}
                                      >
                                        <div
                                          style={{
                                            backgroundColor: '#001d3f',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            color: '#fff',
                                            padding: '5px',
                                          }}
                                        >
                                          {t(el.title, {
                                            lng:
                                              data.language === Languages.RU
                                                ? 'ru'
                                                : 'ro',
                                          })}
                                        </div>
                                        <div>
                                          {el.data.map((row, index) => {
                                            const params =
                                              row?.title?.length >= 30
                                                ? {
                                                    lineHeight: '0.9em',
                                                    fontSize: '0.8em',
                                                  }
                                                : {
                                                    lineHeight: '1em',
                                                    fontSize: '0.9em',
                                                  };

                                            return (
                                              <div
                                                key={index}
                                                style={{
                                                  display: 'flex',
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    color: '#696969',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    width: '40%',
                                                    background: '#f2f2f2',
                                                    borderRight:
                                                      '1px solid #cfcfcf',
                                                    borderBottom:
                                                      '1px solid #cfcfcf',
                                                    padding: '2px',
                                                  }}
                                                >
                                                  <Typography.Text
                                                    style={{
                                                      lineHeight: '1em',
                                                    }}
                                                  >
                                                    {t(row.title, {
                                                      lng:
                                                        data.language ===
                                                        Languages.RU
                                                          ? 'ru'
                                                          : 'ro',
                                                    })}
                                                  </Typography.Text>
                                                </div>
                                                <div
                                                  style={{
                                                    color: '#696969',
                                                    ...params,
                                                    width: '60%',

                                                    borderBottom:
                                                      '1px solid #cfcfcf',
                                                    padding: '2px',
                                                  }}
                                                >
                                                  {row.text}
                                                </div>
                                              </div>
                                            );
                                          })}
                                        </div>
                                      </div>
                                    </div>
                                  );
                                },
                              )}
                            </div>
                            {/* {data.left.map((item, id) => (
                                <div key={id}>
                                  <BlankInfoCard item={item} />
                                </div>
                              ))} */}
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              flex: 1,
                              flexDirection: 'column',
                            }}
                          >
                            <div
                              style={{
                                borderBottom: '1px solid #e6e6e6',
                                flexWrap: 'nowrap',
                                display: 'flex',
                                marginBottom: 12,
                              }}
                            >
                              <Typography style={{ color: '#848484' }}>
                                {t('ships.from', {
                                  lng:
                                    data.language === Languages.RU
                                      ? 'ru'
                                      : 'ro',
                                })}
                              </Typography>
                              <Typography
                                style={{ color: '#101010', margin: '0 auto' }}
                              >
                                {moment(data.createdAt).format('DD.MM.YYYY')}
                              </Typography>
                            </div>

                            <div
                              style={{
                                border: '3px solid #001d3f',
                                borderTop: '1px',
                                borderRadius: '5px',
                                overflow: 'hidden',
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor: '#001d3f',
                                  color: '#fff',
                                  padding: '10px',
                                  textAlign: 'center',
                                }}
                              >
                                {t('ships.direction', {
                                  lng:
                                    data.language === Languages.RU
                                      ? 'ru'
                                      : 'ro',
                                })}
                              </div>
                              <div>
                                <div style={{ display: 'flex' }}>
                                  <div
                                    style={{
                                      color: '#696969',
                                      borderRight: '1px solid #cfcfcf',
                                      background: '#f2f2f2',
                                      width: '40%',
                                      display: 'flex',
                                      alignItems: 'center',
                                      borderBottom: '1px solid #cfcfcf',
                                      padding: '2px',
                                    }}
                                  >
                                    <Typography.Text>
                                      {t('ships.way', {
                                        lng:
                                          data.language === Languages.RU
                                            ? 'ru'
                                            : 'ro',
                                      })}
                                    </Typography.Text>
                                  </div>
                                  <div
                                    style={{
                                      color: '#696969',
                                      borderBottom: '1px solid #cfcfcf',
                                      whiteSpace: 'pre-line',
                                      padding: '2px',
                                      width: '60%',
                                      display: 'flex',
                                      alignItems: 'center',
                                    }}
                                  >
                                    {data.way}
                                  </div>
                                </div>
                                <Row
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    padding: 2,
                                    background: '#e0e0e0',
                                    alignItems: 'center',
                                  }}
                                >
                                  <Typography.Text style={{ color: '#001d3f' }}>
                                    {t('ships.loading', {
                                      lng:
                                        data.language === Languages.RU
                                          ? 'ru'
                                          : 'ro',
                                    })}
                                  </Typography.Text>
                                </Row>
                                {[
                                  {
                                    title: 'ships.shipmentDate',
                                    value: `${moment(data.cargoReady).format(
                                      'DD.MM.YYYY',
                                    )}`,
                                  },
                                  {
                                    title: 'ships.etd',
                                    value: data.etd
                                      ? moment(data.etd).format('DD.MM.YYYY')
                                      : '',
                                  },
                                  {
                                    title: 'ships.cargoSender',
                                    value: `${data.cargoSender.orgName}`,
                                  },
                                  {
                                    title: 'ships.contactLoad',
                                    value: `${
                                      data.contactLoad
                                        ? JSON.parse(data.contactLoad).map(
                                            (contact: any) => {
                                              return (
                                                `${contact.fio} ${contact.phone}` +
                                                '\r\n'
                                              );
                                            },
                                          )
                                        : ''
                                    }`,
                                  },
                                ].map((el, id) => (
                                  <div style={{ display: 'flex' }} key={id}>
                                    <div
                                      style={{
                                        color: '#696969',
                                        borderRight: '1px solid #cfcfcf',
                                        background: '#f2f2f2',
                                        width: '40%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        borderBottom: '1px solid #cfcfcf',
                                        padding: '2px',
                                      }}
                                    >
                                      <Typography.Text>
                                        {t(el.title, {
                                          lng:
                                            data.language === Languages.RU
                                              ? 'ru'
                                              : 'ro',
                                        })}
                                      </Typography.Text>
                                    </div>
                                    <div
                                      style={{
                                        color: '#696969',
                                        display: 'flex',
                                        alignItems: 'center',
                                        width: '60%',

                                        borderBottom: '1px solid #cfcfcf',
                                        whiteSpace: 'pre-line',
                                        padding: '2px',
                                      }}
                                    >
                                      {el.value}
                                    </div>
                                  </div>
                                ))}

                                <Row
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    padding: 2,
                                    backgroundColor: '#e0e0e0',
                                    alignItems: 'center',
                                  }}
                                >
                                  <Typography.Text style={{ color: '#001d3f' }}>
                                    {t('ships.vama', {
                                      lng:
                                        data.language === Languages.RU
                                          ? 'ru'
                                          : 'ro',
                                    })}
                                  </Typography.Text>
                                </Row>
                                {[
                                  {
                                    title: 'ships.vamas',
                                    value: `${data.vamas}`,
                                  },
                                  {
                                    title: 'ships.vamaBroker',
                                    value: `${data.broker}`,
                                  },
                                  {
                                    title: 'ships.vamaImport',
                                    value: `${data.vamaImport}`,
                                  },
                                ].map((el, id) => (
                                  <div style={{ display: 'flex' }} key={id}>
                                    <div
                                      style={{
                                        color: '#696969',
                                        borderRight: '1px solid #cfcfcf',
                                        backgroundColor: '#f2f2f2',
                                        width: '40%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        borderBottom: '1px solid #cfcfcf',
                                        padding: '2px',
                                      }}
                                    >
                                      <Typography.Text>
                                        {t(el.title, {
                                          lng:
                                            data.language === Languages.RU
                                              ? 'ru'
                                              : 'ro',
                                        })}
                                      </Typography.Text>
                                    </div>
                                    <div
                                      style={{
                                        color: '#696969',
                                        display: 'flex',
                                        alignItems: 'center',
                                        width: '60%',

                                        borderBottom: '1px solid #cfcfcf',
                                        whiteSpace: 'pre-line',
                                        padding: '2px',
                                      }}
                                    >
                                      {el.value}
                                    </div>
                                  </div>
                                ))}
                                <Row
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    padding: 2,
                                    background: '#e0e0e0',
                                    alignItems: 'center',
                                  }}
                                >
                                  <Typography.Text style={{ color: '#001d3f' }}>
                                    {t('ships.unloading', {
                                      lng:
                                        data.language === Languages.RU
                                          ? 'ru'
                                          : 'ro',
                                    })}
                                  </Typography.Text>
                                </Row>
                                {[
                                  {
                                    title: 'ships.unloadPerson',
                                    value: `${
                                      data.contactUnLoad
                                        ? JSON.parse(data.contactUnLoad).map(
                                            (contact: any) => {
                                              return (
                                                `${contact.fio} ${contact.phone}` +
                                                '\r\n'
                                              );
                                            },
                                          )
                                        : ''
                                    }`,
                                  },
                                  {
                                    title: 'ships.unloadingAdress',
                                    value: `${data.adressUnloading}`,
                                  },
                                ].map((el, id) => (
                                  <div style={{ display: 'flex' }} key={id}>
                                    <div
                                      style={{
                                        color: '#696969',
                                        borderRight: '1px solid #cfcfcf',
                                        background: '#f2f2f2',
                                        width: '40%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        borderBottom: '1px solid #cfcfcf',
                                        padding: '2px',
                                      }}
                                    >
                                      <Typography.Text>
                                        {t(el.title, {
                                          lng:
                                            data.language === Languages.RU
                                              ? 'ru'
                                              : 'ro',
                                        })}
                                      </Typography.Text>
                                    </div>
                                    <div
                                      style={{
                                        color: '#696969',
                                        display: 'flex',
                                        alignItems: 'center',
                                        width: '60%',

                                        borderBottom: '1px solid #cfcfcf',
                                        whiteSpace: 'pre-line',
                                        padding: '2px',
                                      }}
                                    >
                                      {el.value}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '12px',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '12px',
                        }}
                      >
                        <div style={{ width: '50%' }}>
                          <div
                            style={{
                              borderBottom: '1px solid #e6e6e6',
                              flexWrap: 'wrap',
                              display: 'flex',
                            }}
                          >
                            <Typography.Text
                              style={{
                                color: '#848484',
                                width: '100%',
                              }}
                            >
                              {t('ships.zakazcik')}
                            </Typography.Text>
                            <Typography
                              style={{ color: '#101010', fontWeight: 'bold' }}
                            >
                              {data.client.orgName}
                            </Typography>
                          </div>
                        </div>
                        <div style={{ width: '50%' }}>
                          <div
                            style={{
                              borderBottom: '1px solid #e6e6e6',
                              flexWrap: 'wrap',
                              display: 'flex',
                            }}
                          >
                            {visibleStamp && (
                              <img
                                src={`${
                                  process.env.PUBLIC_URL
                                }/img/stamp_${checkFirm(data.company)}.png`}
                                alt="Печать фирмы"
                                style={{
                                  position: 'absolute',
                                  right: '60px',
                                  bottom: '40px',
                                  transform: 'rotate(-7deg)',
                                  width: '60mm',
                                }}
                              />
                            )}
                            <Typography
                              style={{ color: '#848484', width: '100%' }}
                            >
                              {t('ships.expeditor')}
                            </Typography>
                            <Typography.Text
                              style={{
                                fontWeight: 'bold',
                              }}
                            >
                              {t(`${checkFirm(data.company)}.label`, {
                                lng:
                                  data.language === Languages.RU ? 'ru' : 'ro',
                              })}
                            </Typography.Text>
                            {/* <Typography style={{ whiteSpace: 'pre-line' }}>
                                {translate(`${checkFirm(data.company)}.rekv`)}
                              </Typography> */}
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        1/2
                      </div>
                    </div>
                  </div>

                  {/* <div
                    style={{
                      width: '21cm',
                      maxWidth: '21cm',
                      minHeight: '29.7cm',
                      maxHeight: '29.7cm',
                      backgroundColor: '#ffffff',
                      padding: '1.2cm 0.7cm',
                      display: 'flex',
                      position: 'relative',

                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      margin: 'auto',
                    }}
                  >
                    <div
                      style={{
                        position: 'relative',
                        height: '100%',
                        gap: '12px',
                      }}
                    >
                      <div>
                        <img
                          src={`${
                            process.env.PUBLIC_URL
                          }/img/ico_logo.${checkFirm(data.company)}.png`}
                          alt="Real Cargo Logo"
                          style={{ marginTop: '40px', width: '300px' }}
                        />
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          marginTop: '16px',
                        }}
                      >
                        <Typography.Text
                          style={{ fontSize: '20px', fontWeight: 600 }}
                        >
                          {t('blank.mainLabel', {
                            lng: data.language === Languages.RU ? 'ru' : 'ro',
                          })}{' '}
                          №{data?.offerNumber}
                        </Typography.Text>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          margin: '16px 0',
                        }}
                      >
                        <div
                          style={{ display: 'flex', flexDirection: 'column' }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              width: '100%',
                              gap: '12px',
                              margin: '16px 0',
                            }}
                          >
                            <div style={{ width: '50%' }}>
                              <div
                                style={{
                                  borderBottom: '1px solid #e6e6e6',
                                  flexWrap: 'nowrap',
                                  display: 'flex',
                                }}
                              >
                                <Typography style={{ color: '#848484' }}>
                                  {t('blank.for', {
                                    lng:
                                      data.language === Languages.RU
                                        ? 'ru'
                                        : 'ro',
                                  })}
                                </Typography>
                                <Typography
                                  style={{
                                    color: '#101010',
                                    margin: '0 auto',
                                  }}
                                >
                                  TEST
                                </Typography>
                              </div>
                            </div>
                            <div style={{ width: '50%' }}>
                              <Col
                                style={{
                                  borderBottom: '1px solid #e6e6e6',
                                  flexWrap: 'nowrap',
                                  display: 'flex',
                                }}
                              >
                                <Typography style={{ color: '#848484' }}>
                                  {t('blank.from', {
                                    lng:
                                      data.language === Languages.RU
                                        ? 'ru'
                                        : 'ro',
                                  })}
                                </Typography>

                                <Typography
                                  style={{
                                    color: '#101010',
                                    margin: '0 auto',
                                  }}
                                >
                                  {moment(data.createdAt).format('DD.MM.YYYY')}
                                </Typography>
                              </Col>
                            </div>
                          </div>
                          <div style={{ width: '100%', margin: '0 0 12px 0' }}>
                            <div
                              style={{
                                border: '1px solid #cfcfcf',
                                overflow: 'hidden',
                                borderRadius: '8px',
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor: '#001d3f',
                                  color: '#fff',

                                  display: 'flex',
                                  justifyContent: 'center',
                                }}
                              >
                                <Typography.Text
                                  style={{ color: '#fff', fontSize: '1rem' }}
                                >
                                  {t('blank.conditions.label', {
                                    lng:
                                      data.language === Languages.RU
                                        ? 'ru'
                                        : 'ro',
                                  })}
                                </Typography.Text>
                              </div>
                              <div
                                style={{
                                  fontSize: '0.7em',
                                  lineHeight: '1em',
                                  padding: '10px',
                                  textAlign: 'justify',
                                }}
                              >
                                TEST
                              </div>
                              <div style={{ padding: '10px' }}>
                                <Typography color="#001d3f">
                                  {t('blank.conditions.subtitle', {
                                    lng:
                                      data.language === Languages.RU
                                        ? 'ru'
                                        : 'ro',
                                  })}
                                </Typography>
                              </div>
                            </div>
                          </div>
                          <div style={{ width: '100%' }}>
                            <div style={{ display: 'flex', gap: '12px' }}>
                              <div style={{ width: '50%' }}>
                                <div
                                  style={{
                                    border: '1px solid #e0e0e0',
                                    overflow: 'hidden',
                                    borderRadius: '8px',
                                    height: '100%',
                                  }}
                                >
                                  <div
                                    style={{
                                      background: '#e0e0e0',
                                      display: 'flex',
                                      justifyContent: 'center',
                                      padding: 1,
                                      color: '#001d3f',
                                    }}
                                  >
                                    <Typography>TEST</Typography>
                                  </div>
                                  <div
                                    style={{
                                      padding: 10,
                                      display: 'flex',
                                      flexDirection: 'column',
                                    }}
                                  >
                                    <Typography.Text
                                      style={{
                                        fontWeight: 'bold',
                                        whiteSpace: 'pre-line',
                                        display: 'inline-block',
                                        lineHeight: '1.3em',
                                      }}
                                    >
                                      TEST
                                    </Typography.Text>
                                    <Typography
                                      style={{
                                        whiteSpace: 'pre-line',
                                        display: 'inline-block',
                                        lineHeight: '1.3em',
                                      }}
                                    >
                                      TEST
                                    </Typography>
                                  </div>
                                </div>
                              </div>
                              <div style={{ width: '50%' }}>
                                <div
                                  style={{
                                    border: '1px solid #e0e0e0',
                                    // overflow: 'hidden',
                                    borderRadius: '8px',
                                    height: '100%',
                                  }}
                                >
                                  <div
                                    style={{
                                      background: '#e0e0e0',
                                      display: 'flex',
                                      justifyContent: 'center',
                                      color: '#001d3f',
                                      padding: 1,
                                    }}
                                  >
                                    <Typography>TEST</Typography>
                                  </div>
                                  <div
                                    style={{
                                      padding: 10,
                                      position: 'relative',
                                    }}
                                  >
                                    {visibleStamp && (
                                      <img
                                        src={`${
                                          process.env.PUBLIC_URL
                                        }/img/stamp_${checkFirm(
                                          data.company,
                                        )}.png`}
                                        alt="Печать фирмы"
                                        style={{
                                          position: 'absolute',
                                          right: '0px',
                                          bottom: '-30px',
                                          transform: 'rotate(-15deg)',
                                          width: '60mm',
                                        }}
                                      />
                                    )}
                                    <Typography.Text
                                      style={{
                                        fontWeight: 'bold',
                                      }}
                                    >
                                      {t(`${checkFirm(data.company)}.label`, {
                                        lng:
                                          data.language === Languages.RU
                                            ? 'ru'
                                            : 'ro',
                                      })}
                                    </Typography.Text>
                                    <Typography
                                      style={{ whiteSpace: 'pre-line' }}
                                    >
                                      {t(`${checkFirm(data.company)}.rekv`, {
                                        lng:
                                          data.language === Languages.RU
                                            ? 'ru'
                                            : 'ro',
                                      })}
                                    </Typography>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      2/2
                    </div>
                  </div> */}
                </div>
              )}
            </Col>
          </Row>
        </>
      </div>
    </CanAccess>
  ) : (
    <Spin>Загрузка</Spin>
  );
};
