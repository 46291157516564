import {
  CanAccess,
  CrudFilters,
  IResourceComponentsProps,
  useCan,
  useGetIdentity,
  useNavigation,
} from '@pankod/refine-core';
import {
  Alert,
  Badge,
  Button,
  Dropdown,
  FilterDropdown,
  FilterDropdownProps,
  getDefaultSortOrder,
  Input,
  List,
  MenuProps,
  message,
  NumberField,
  Select,
  ShowButton,
  Space,
  Spin,
  Table,
  Tabs,
  Tag,
  TagField,
  TextField,
  Tooltip,
  Typography,
  useTable,
} from '@pankod/refine-antd';

import {
  SolutionOutlined,
  FileAddOutlined,
  UserOutlined,
  DownOutlined,
  EllipsisOutlined,
  CalendarOutlined,
  MailOutlined,
  SignatureOutlined,
  WarningOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { IUser } from 'interfaces';
import { UserStatus } from '../../enums/user.status';
import React, { CSSProperties, useContext, useState } from 'react';
import { Role } from '../../enums/role';
import { UserContext } from 'UserProvider';
import ModalAddLender from 'components/modals/addCompanies';
import { ClientType } from 'enums/client.type';
import ModalAddCalendar from 'components/calendar/addCalendar';
import { CalendarType } from 'enums/calendar.type';
import { useTranslation } from 'react-i18next';
import ModalEditCompany from 'components/modals/editCompanies';
import { tags } from 'enums/tags';
import FirstLesson from './lessons/first';
import { Flex } from 'antd';
import SecondLesson from './lessons/second';
import ThirdLesson from './lessons/third';
import GeotransLesson from './lessons/geotrans';
import FourLesson from './lessons/four';
import Exam from 'components/exam';

export const Education: React.FC<IResourceComponentsProps> = () => {
  const { t } = useTranslation();
  const tabs = [
    {
      label: t('education.lesson.first', 'Урок первый'),
      key: 'Tab1',
      children: <FirstLesson />,
    },
    {
      label: t('education.lesson.second', 'Урок второй'),
      key: 'Tab2',
      children: <SecondLesson />,
    },
    {
      label: t('education.lesson.third', 'Урок третий'),
      key: 'Tab3',
      children: <ThirdLesson />,
    },
    {
      label: t('education.lesson.four', 'Урок четвертый'),
      key: 'Tab4',
      children: <FourLesson />,
    },
    {
      label: t('education.lesson.geotrans', 'GeoTrans'),
      key: 'Tab5',
      children: <GeotransLesson />,
    },
    {
      label: t('education.lesson.exam', 'Контрольный экзамен'),
      key: 'Tab6',
      children: <Exam />,
    },
  ];

  return (
    <Flex gap={16} vertical>
      <Alert
        showIcon
        description={
          <Typography.Title level={5}>
            Для последующего прохождения теста , вам необходимо просмотреть все
            уроки
          </Typography.Title>
        }
      />
      <Tabs tabPosition="left" items={tabs} />
    </Flex>
  );
};
