import { Collapse, CollapseProps, Flex, theme, Typography } from 'antd';
import React, { CSSProperties } from 'react';
import { CaretRightOutlined } from '@ant-design/icons';
import { Link } from '@pankod/refine-react-router-v6';

const GeotransLesson = () => {
  const { token } = theme.useToken();

  const panelStyle: React.CSSProperties = {
    marginBottom: 24,
    background: token.colorBgContainer,
    borderRadius: token.borderRadiusLG,
  };

  return (
    <Flex vertical style={{ maxWidth: 1000 }}>
      <Typography.Title level={4}>GEOTRANS</Typography.Title>
      <Typography.Title level={5}>
        В видео ниже, вы ознакомитесь с системой GeoTrans
      </Typography.Title>
      <video width="640" height="360" controls>
        <source src="video/geotrans.mov" />
        Ваш браузер не поддерживает видео.
      </video>
    </Flex>
  );
};

export default GeotransLesson;
