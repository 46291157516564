import { Collapse, CollapseProps, Flex, theme, Typography } from 'antd';
import React, { CSSProperties } from 'react';

import { CaretRightOutlined } from '@ant-design/icons';

const getItems: (panelStyle: CSSProperties) => CollapseProps['items'] = (
  panelStyle,
) => [
  {
    key: '1',
    label: 'Что такое Логистика ?',
    children: (
      <Flex>
        <iframe
          width="100%"
          height="315"
          src={'https://www.youtube.com/embed/B9qbERGphG8'}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Flex>
    ),
    style: panelStyle,
  },
  {
    key: '2',
    label: 'Логист, что нужно знать ?',
    children: (
      <Flex>
        <iframe
          width="100%"
          height="315"
          src={'https://www.youtube.com/embed/BlMdTPPBMq0'}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Flex>
    ),
    style: panelStyle,
  },
  {
    key: '3',
    label: 'И всё-же , что такое логистика ?',
    children: (
      <Flex>
        <iframe
          width="100%"
          height="315"
          src={'https://www.youtube.com/embed/wtlM-pAMWAA'}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Flex>
    ),
    style: panelStyle,
  },
  {
    key: '4',
    label: 'Как стать логистом ?',
    children: (
      <Flex>
        <iframe
          width="100%"
          height="315"
          src={'https://www.youtube.com/embed/mPEBjJ-s7L0'}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Flex>
    ),
    style: panelStyle,
  },
  {
    key: '5',
    label: 'Проблемы с которыми можете столкнуться ',
    children: (
      <Flex>
        <iframe
          width="100%"
          height="315"
          src={'https://www.youtube.com/embed/ZQo8NO9zq7Q'}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Flex>
    ),
    style: panelStyle,
  },
  {
    key: '6',
    label: '5 основых правил логиста',
    children: (
      <Flex>
        <iframe
          width="100%"
          height="315"
          src={'https://www.youtube.com/embed/pCmbP90mpyE'}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Flex>
    ),
    style: panelStyle,
  },
  {
    key: '7',
    label: 'В чем отличия Экспедитора от Диспетчера ?',
    children: (
      <Flex>
        <iframe
          width="100%"
          height="315"
          src={'https://www.youtube.com/embed/EdS6-LcG1Cw'}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Flex>
    ),
    style: panelStyle,
  },
  {
    key: '8',
    label: 'Как работает экспедитор ?',
    children: (
      <Flex>
        <iframe
          width="100%"
          height="315"
          src={'https://www.youtube.com/embed/50d3hsRl_7c'}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Flex>
    ),
    style: panelStyle,
  },
];

const FirstLesson = () => {
  const { token } = theme.useToken();

  const panelStyle: React.CSSProperties = {
    marginBottom: 24,
    background: token.colorBgContainer,
    borderRadius: token.borderRadiusLG,
  };

  return (
    <Flex vertical style={{ maxWidth: 1000 }}>
      <Typography.Title level={4}>Урок первый</Typography.Title>
      <Typography.Title level={5}>
        Знакомства с нашей сферой деятельности:
      </Typography.Title>
      <Typography>
        Это первый урок в обучении нашей сверке деятельностью. В этом уроке мы
        познакомимся с основными компетенциями и навыками, которые вам
        потребуются в работе .
      </Typography>
      <Collapse
        style={{ background: 'transparent' }}
        bordered={false}
        defaultActiveKey={['1']}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
        items={getItems(panelStyle)}
      />
      <Typography.Title level={5}>
        Если вы просмотрели видео-обучение из этого урока , можете перейти к
        уроку №2
      </Typography.Title>
    </Flex>
  );
};

export default FirstLesson;
