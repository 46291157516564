import {
  CanAccess,
  CrudFilters,
  IResourceComponentsProps,
  useCan,
  useGetIdentity,
  useNavigation,
} from '@pankod/refine-core';
import {
  Avatar,
  Badge,
  Button,
  CreateButton,
  DateField,
  DeleteButton,
  Dropdown,
  EditButton,
  EmailField,
  FilterDropdown,
  FilterDropdownProps,
  getDefaultSortOrder,
  Input,
  List,
  MenuItemProps,
  MenuProps,
  message,
  NumberField,
  Select,
  ShowButton,
  Space,
  Spin,
  Table,
  Tag,
  TagField,
  TextField,
  Tooltip,
  useTable,
} from '@pankod/refine-antd';

import {
  SolutionOutlined,
  FileAddOutlined,
  UserOutlined,
  DownOutlined,
  EllipsisOutlined,
  CalendarOutlined,
  MailOutlined,
  SignatureOutlined,
  WarningOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { IUser } from 'interfaces';
import { UserStatus } from '../../enums/user.status';
import React, { CSSProperties, useContext, useState } from 'react';
import { Role } from '../../enums/role';
import { UserContext } from 'UserProvider';
import ModalAddLender from 'components/modals/addCompanies';
import { ClientType } from 'enums/client.type';
import ModalAddCalendar from 'components/calendar/addCalendar';
import { CalendarType } from 'enums/calendar.type';
import { useTranslation } from 'react-i18next';
import ModalEditCompany from 'components/modals/editCompanies';
import { tags } from 'enums/tags';

export const DriversList: React.FC<IResourceComponentsProps> = () => {
  const { t } = useTranslation();
  const { list } = useNavigation();
  const [open, setOpen] = React.useState<boolean>(false);
  const [selectedUuid, setSelectedUuid] = React.useState<number | null>(null);
  const userContext = useContext(UserContext);
  const me = userContext ? userContext.user : null;
  const pageSizeOptions = Number(localStorage.getItem('pageSizeOptions')) || 10;

  const initialFilter: CrudFilters = [];

  const { tableProps, sorter, tableQueryResult, setPageSize, setCurrent } =
    useTable<IUser>({
      resource: 'companies/drivers',
      queryOptions: { retry: 5 },
      // initialSorter: [
      //   {
      //     field: 'createdAt',
      //     order: 'desc',
      //   },
      // ],
      initialPageSize: pageSizeOptions,

      initialFilter,
    });

  const calendar: boolean =
    useCan({ resource: 'firms', action: 'calendar' }).data?.can || false;
  const edit: boolean =
    useCan({ resource: 'firms', action: 'edit' }).data?.can || false;
  const changeUser: boolean =
    useCan({ resource: 'firms', action: 'changeUser' }).data?.can || false;

  const { refetch } = tableQueryResult;
  const [openModalAdd, setOpenModal] = useState<boolean>(false);
  const [openModalEdit, setModalEdit] = useState<boolean>(false);
  const [openModalAddCalendar, setOpenModalCalendar] = useState<boolean>(false);

  const items = (value: any): MenuProps['items'] => {
    return [
      {
        label: t('calendar.title'),
        key: '1',
        icon: <CalendarOutlined />,
        onClick: () => {
          setSelectedUuid(value.uuid);
          setOpenModalCalendar(true);
        },
      },
      {
        label: 'Копировать Email',
        key: '2',
        disabled: true,
        icon: <MailOutlined />,
      },
      {
        // label: 'Редактировать',
        key: '3',
        // icon: <UserOutlined />,
        type: 'divider',
      },
      {
        label: 'Редактировать',
        key: '4',
        disabled: !edit,
        icon: <SignatureOutlined />,
        onClick: () => {
          setSelectedUuid(value.uuid);
          setModalEdit(true);
        },
      },
    ];
  };

  const menuProps = (value: any) => {
    const itms = items(value);

    return { items: itms };
  };

  const [openAssigHistory, setOpenAssigHistory] = useState<boolean>(false);

  const companiesMenuItem = (value: any): MenuProps['items'] => [
    {
      key: '1',
      label: (
        <div onClick={() => list(`blanks/offers?drivers=${value}`)}>
          История логистики
        </div>
      ),
    },
    {
      key: '2',
      disabled: true,
      label: <>История морских перевозок</>,
    },
    {
      key: '3',
      disabled: true,
      label: <>История ж/д перевозок</>,
    },
  ];

  return !tableProps.loading ? (
    <>
      <CanAccess resource="firms" action="create">
        {openModalAdd && (
          <ModalAddLender
            open={openModalAdd}
            onCancel={() => setOpenModal(false)}
            refetch={refetch}
            type={ClientType.TRANSPORT}
          />
        )}
      </CanAccess>
      {openModalAddCalendar && (
        <ModalAddCalendar
          open={openModalAddCalendar}
          onCancel={() => setOpenModalCalendar(false)}
          refetch={refetch}
          type={CalendarType.ASSIG}
          uuid={Number(selectedUuid)}
        />
      )}
      <CanAccess resource="firms" action="edit">
        {openModalEdit && me && (
          <ModalEditCompany
            open={openModalEdit}
            onCancel={() => setModalEdit(false)}
            refetch={refetch}
            uuid={Number(selectedUuid)}
            me={me}
          />
        )}
      </CanAccess>
      <List
        headerProps={{
          extra: (
            <Button
              onClick={() => setOpenModal(true)}
              type={'primary'}
              children="Добавить фирму"
            />
          ),
        }}
      >
        <Table
          {...tableProps}
          rowKey="uuid"
          pagination={{
            ...tableProps.pagination,
            position: ['bottomRight'],
            showSizeChanger: true,
            pageSizeOptions: ['10', '50', '100', '200'],
            size: 'small',
          }}
          // onChange={(value) => {
          //   if (value.pageSize) {
          //     localStorage.setItem('pageSizeOptions', String(value.pageSize));
          //     setPageSize(value.pageSize);
          //   }
          //   if (value.current) {
          //     setCurrent(value.current);
          //   }
          // }}
          size="small"
        >
          <Table.Column
            dataIndex="orgName"
            key="orgName"
            title="Название"
            render={(value, record: any) => (
              <>
                {' '}
                <Dropdown
                  menu={{ items: companiesMenuItem(record.uuid) }}
                  placement="bottomRight"
                  arrow
                  disabled={+record.offerDriver?.length < 1}
                  trigger={['click']}
                >
                  <Badge
                    count={record.offerDriver?.length}
                    style={{ background: 'green' }}
                  >
                    <Tag
                      bordered
                      style={{
                        width: '100%',
                        display: 'flex',
                        padding: '4px',
                        justifyContent: 'center',
                        cursor: 'pointer',
                      }}
                      color={'#154468'}
                      onClick={() => {
                        if (me?.role === Role.MANAGER_ASIG) {
                          setOpenAssigHistory(true);
                          setSelectedUuid(record.uuid);
                        }
                      }}
                      // onClick={() =>
                      // show('offers', `${value.uuid}&${ClientType.FIRM}`)
                      // }
                    >
                      <div
                        style={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {value}
                      </div>
                    </Tag>
                  </Badge>
                </Dropdown>
              </>
            )}
            defaultSortOrder={getDefaultSortOrder('orgName', sorter)}
            sorter
            filterDropdown={(props: FilterDropdownProps) => (
              <FilterDropdown {...props}>
                <Input />
              </FilterDropdown>
            )}
          />

          <Table.Column
            dataIndex="contact"
            key="contact"
            title="Контакты"
            render={(value) => {
              // return value;
              if (!value) return <></>;
              const array = JSON.parse(value);

              return (
                <Space size={4} direction="vertical">
                  {array.map((contact: any) => {
                    return (
                      <div>
                        <TextField value={contact.name} />{' '}
                        <TextField value={contact.phone} />
                      </div>
                    );
                  })}
                </Space>
              );
            }}
          />
          <Table.Column
            dataIndex="comment"
            key="comment"
            title="Комментарии"
            render={(value) => <TextField value={value} />}
          />
          <Table.Column
            dataIndex="tags"
            key="tags"
            title="Теги"
            render={(value) => (
              <TextField value={JSON.parse(value).join(' ')} />
            )}
            defaultSortOrder={getDefaultSortOrder('tags', sorter)}
            filterDropdown={(props) => (
              <FilterDropdown {...props}>
                <Select
                  style={{ minWidth: 200 }}
                  mode="multiple"
                  placeholder="Выберите ТЕГИ"
                  options={tags?.map((tag) => ({
                    label: tag.value,
                    value: tag.value,
                  }))}
                  onSelect={(selectedTags) => {
                    props.confirm();
                  }}
                  onDeselect={props.confirm}
                />
              </FilterDropdown>
            )}
            sorter
          />

          <Table.Column
            key="action"
            title=""
            width={20}
            render={(_, value: any) => (
              <>
                <Dropdown
                  menu={menuProps(value)}
                  arrow
                  trigger={['click']}
                  placement="bottomLeft"
                  destroyPopupOnHide
                >
                  <Button
                    icon={<EllipsisOutlined />}
                    shape="circle"
                    type="dashed"
                  />
                </Dropdown>
              </>
            )}
          />
        </Table>
      </List>
    </>
  ) : (
    <Spin>Загрузка</Spin>
  );
};
