import { useList, useNotification } from '@pankod/refine-core';
import {
  Button,
  Flex,
  Form,
  Radio,
  RadioChangeEvent,
  Steps,
  Typography,
} from 'antd';
import { axiosInstance } from 'App';
import React, { useState } from 'react';

const Exam = () => {
  const [form] = Form.useForm();
  const { open: notify } = useNotification();
  const { data, isLoading, refetch } = useList({
    resource: 'exam',
    config: { hasPagination: false },
  });
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [arrayAnswers, setArrayAnswers] = useState<
    { uuid: string; answer: number }[]
  >([]);
  const handleAnswer = (event: RadioChangeEvent) => {
    // setArrayAnswers;
    if (currentStep < 19) {
      setCurrentStep(currentStep + 1);
      form.resetFields();
    }

    setArrayAnswers((prew) => [
      ...prew,
      { uuid: data?.data[currentStep].uuid, answer: +event.target.value },
    ]);
  };

  const checkExam = async () => {
    setLoading(true);
    try {
      const { data } = await axiosInstance.post(
        process.env.REACT_APP_BACKEND_URL + '/api/v1/exam/check',
        {
          answers: arrayAnswers,
        },
      );
      if (notify) {
        if (data.result) {
          notify({
            message:
              'Результат экзамена положительный, обновляются данные профиля ожидайте',
            type: 'success',
            description: 'Поздравляем!',
          });
          // window.location.reload();
        } else {
          notify({
            message: `К сожалению результат экзамена отрицательный, у вас было ${data.count} ошибок`,
            type: 'error',
            description: 'Плохо!',
          });
          refetch();
          form.resetFields();
          setCurrentStep(0);
          setArrayAnswers([]);
        }
      }
      setLoading(false);
    } catch (error) {
      if (notify) {
        notify({
          message:
            'Возникла ошибка отправки результатов, попробуйте повторить позднее',
          type: 'error',
          description: 'Ошибка',
          undoableTimeout: 1,
        });
        setLoading(false);
      }
    }
  };

  return (
    <Flex vertical gap={'middle'}>
      <Typography.Title level={5}>
        Контрольный экзамен, состоит из 20 вопросов, 1 попытка на прохождение
        данного теста
      </Typography.Title>
      <Steps current={currentStep} percent={currentStep * 5} size="small">
        {data?.data.map((el, key) => (
          <Steps.Step key={key} />
        ))}
      </Steps>
      <Flex vertical gap={'middle'}>
        <Form form={form} onFinish={checkExam} layout="vertical">
          {data && arrayAnswers.length < data.data.length ? (
            <Form.Item
              name="answers"
              label={
                <Typography.Title level={5}>
                  {data?.data[currentStep].question}
                </Typography.Title>
              }
            >
              <Radio.Group buttonStyle="solid" onChange={handleAnswer}>
                <Flex vertical gap={'middle'}>
                  <Radio value={1}>{data?.data[currentStep].answOne}</Radio>
                  <Radio value={2}>{data?.data[currentStep].answTwo}</Radio>
                  <Radio value={3}>{data?.data[currentStep].answThree}</Radio>
                  <Radio value={4}>{data?.data[currentStep].answFour}</Radio>
                </Flex>
              </Radio.Group>
            </Form.Item>
          ) : (
            <Form.Item
              label={
                <Typography.Title level={5}>
                  Вы ответили на все вопросы, для того чтобы проверить прошли ли
                  тест необходимо отправить на проверку. Результаты теста будут
                  отправлены Вам и Директору на почту
                </Typography.Title>
              }
            >
              <Button loading={loading} htmlType="submit" type="primary">
                Отправить на проверку
              </Button>
            </Form.Item>
          )}
        </Form>
      </Flex>
    </Flex>
  );
};

export default Exam;
