import { Collapse, CollapseProps, Flex, theme, Typography } from 'antd';
import React, { CSSProperties } from 'react';
import { CaretRightOutlined } from '@ant-design/icons';
import { Link } from '@pankod/refine-react-router-v6';

const getItems: (panelStyle: CSSProperties) => CollapseProps['items'] = (
  panelStyle,
) => [
  {
    key: '1',
    label: 'Документы для международных перевозок',
    children: (
      <Flex>
        <iframe
          width="100%"
          height="315"
          src={'https://www.youtube.com/embed/iXQbLWTjPoM'}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Flex>
    ),
    style: panelStyle,
  },
  {
    key: '2',
    label: 'Какие документы на груз нужны ?',
    children: (
      <Flex>
        <iframe
          width="100%"
          height="315"
          src={'https://www.youtube.com/embed/M8valV6QLjU'}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Flex>
    ),
    style: panelStyle,
  },
  {
    key: '3',
    label: 'CMR, и все что с ним связано',
    children: (
      <Flex>
        <iframe
          width="100%"
          height="315"
          src={'https://www.youtube.com/embed/B_FWM6Unoj8'}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Flex>
    ),
    style: panelStyle,
  },
  {
    key: '4',
    label: 'CMR, продолжение',
    children: (
      <Flex>
        <iframe
          width="100%"
          height="315"
          src={'https://www.youtube.com/embed/uExgdcZq0P4'}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Flex>
    ),
    style: panelStyle,
  },
  {
    key: '5',
    label: 'Всё о TIR',
    children: (
      <Flex>
        <iframe
          width="100%"
          height="315"
          src={'https://www.youtube.com/embed/OsKA4FlllXM'}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Flex>
    ),
    style: panelStyle,
  },
  {
    key: '6',
    label: 'Разница между CMR и TIR',
    children: (
      <Flex>
        <iframe
          width="100%"
          height="315"
          src={'https://www.youtube.com/embed/KJjQ2R-cS1A'}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Flex>
    ),
    style: panelStyle,
  },
  {
    key: '7',
    label: 'Разрешительная система EKMT',
    children: (
      <Flex>
        <iframe
          width="100%"
          height="315"
          src={'https://www.youtube.com/embed/EJ2TW1r1d0w'}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Flex>
    ),
    style: panelStyle,
  },
  {
    key: '8',
    label: 'Транзитная декларация',
    children: (
      <Flex>
        <iframe
          width="100%"
          height="315"
          src={'https://www.youtube.com/embed/01FsFVcD-oc'}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Flex>
    ),
    style: panelStyle,
  },
  {
    key: '9',
    label: 'CMR страхование',
    children: (
      <Flex>
        <iframe
          width="100%"
          height="315"
          src={'https://www.youtube.com/embed/FEHq1k2UA4E'}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Flex>
    ),
    style: panelStyle,
  },
  {
    key: '10',
    label: 'Экспортная декларация',
    children: (
      <Flex>
        <iframe
          width="100%"
          height="315"
          src={'https://www.youtube.com/embed/Xu99g34OCPE'}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Flex>
    ),
    style: panelStyle,
  },
  {
    key: '11',
    label: 'Образец инвойса и как правильно его заполнить',
    children: (
      <Flex>
        <iframe
          width="100%"
          height="315"
          src={'https://www.youtube.com/embed/-3MryDYcT8g'}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Flex>
    ),
    style: panelStyle,
  },
  {
    key: '12',
    label: 'Акт выполненных работ без НДС',
    children: (
      <Flex>
        <iframe
          width="100%"
          height="315"
          src={'https://www.youtube.com/embed/UHcUyBDZzP4'}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Flex>
    ),
    style: panelStyle,
  },
  {
    key: '14',
    label: 'Фитосанитарный сертификат',
    children: (
      <Flex vertical>
        <iframe
          width="100%"
          height="315"
          src={'https://www.youtube.com/embed/slTO00Sd7VE'}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Flex>
    ),
    style: panelStyle,
  },
  {
    key: '15',
    label: 'Фитосанитарный сертификат 2',
    children: (
      <Flex vertical>
        <iframe
          width="100%"
          height="315"
          src={'https://www.youtube.com/embed/7_UfCeTcikk'}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Flex>
    ),
    style: panelStyle,
  },
  {
    key: '16',
    label: 'Базовый курс',
    children: (
      <Flex vertical>
        <iframe
          width="100%"
          height="315"
          src={'https://www.youtube.com/embed/jJqarzZXyp8'}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Flex>
    ),
    style: panelStyle,
  },
];
const ThirdLesson = () => {
  const { token } = theme.useToken();

  const panelStyle: React.CSSProperties = {
    marginBottom: 24,
    background: token.colorBgContainer,
    borderRadius: token.borderRadiusLG,
  };

  return (
    <Flex vertical style={{ maxWidth: 1000 }}>
      <Typography.Title level={4}>Урок третий</Typography.Title>
      <Typography.Title level={5}>
        Мы продолжаем изучать профессию ЛОГИСТ. В этом уроке мы рассмотрим
        основные понятия, требования и умения, которые потребуются вам для
        успешной работы в Логистике.
      </Typography.Title>
      <Typography.Title level={5}>
        Знакомства с документами. Сопроводительные документы, транзитные и
        электронные декларации. Сертификаты качества и происхождения, а так же
        разрешение и классификация опасных грузов. Фито-санитарное разрешение и
        страховка СМR.
      </Typography.Title>
      <Collapse
        style={{ background: 'transparent' }}
        bordered={false}
        defaultActiveKey={['1']}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
        items={getItems(panelStyle)}
      />
      <Typography.Title level={5}>
        Если вы просмотрели видео-обучение из этого урока , можете перейти к
        изучению урока 4
      </Typography.Title>
    </Flex>
  );
};

export default ThirdLesson;
