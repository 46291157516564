import {
  CanAccess,
  CrudFilters,
  IResourceComponentsProps,
  useCan,
  useDelete,
  useNavigation,
  usePermissions,
  useSelect,
} from '@pankod/refine-core';
import {
  Avatar,
  Button,
  Col,
  CreateButton,
  DatePicker,
  Dropdown,
  Input,
  List,
  MenuProps,
  Modal,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Tag,
  TagField,
  TextField,
  Tooltip,
  Typography,
  useTable,
} from '@pankod/refine-antd';

import {
  SolutionOutlined,
  FileAddOutlined,
  UserOutlined,
  DownOutlined,
  EllipsisOutlined,
  CalendarOutlined,
  MailOutlined,
  SignatureOutlined,
  WarningOutlined,
  DeleteOutlined,
  BookOutlined,
  CarOutlined,
  EnvironmentOutlined,
  FileProtectOutlined,
  CopyFilled,
  FileTextFilled,
  BankFilled,
} from '@ant-design/icons';
import { ICompanies, IUser } from 'interfaces';
import React, { Suspense, useContext, useState } from 'react';
import { Role } from '../../enums/role';
import { UserContext } from 'UserProvider';
import { ClientType } from 'enums/client.type';
import moment from 'moment';
import { Firms } from 'enums/firms.type';
import { firmColor } from 'utility/getColor';
import { useSearchParams } from '@pankod/refine-react-router-v6';
import { axiosInstance } from 'App';
import { useTranslation } from 'react-i18next';
import ModalEditAct from 'components/modals/editAct';
import dayjs from 'dayjs';
import { UserStatus } from 'enums/user.status';
import { VamaStatus } from 'enums/vama.status.type';

export const OffersList: React.FC<IResourceComponentsProps> = () => {
  const { t } = useTranslation();
  const { show, replace, edit: openEdit } = useNavigation();
  const userContext = useContext(UserContext);
  const me = userContext ? userContext.user : null;
  const [searchParams] = useSearchParams();
  const { mutate: deleteOne } = useDelete({});
  const allParams = Object.fromEntries(searchParams);
  const pageSizeOptions = Number(localStorage.getItem('pageSizeOptions')) || 10;

  let initialFilter: CrudFilters = [
    {
      field: 'offer.createdAt',
      operator: 'gte',
      value: `${dayjs().format('YYYY')}-${dayjs().format('MM')}-01`,
    },
    {
      field: 'offer.createdAt',
      operator: 'lt',
      value: `${dayjs().add(1, 'month').format('YYYY')}-${dayjs()
        .add(1, 'month')
        .format('MM')}-01`,
    },
    { field: 'user.uuid', value: me?.uuid || null, operator: 'eq' },
  ];

  if (allParams.uuid) {
    initialFilter = [
      { field: 'offer.uuid', value: allParams.uuid, operator: 'eq' },
    ];
  } else if (allParams.companies) {
    initialFilter = [
      {
        field: 'offer.offerLender.uuid',
        value: allParams.companies,
        operator: 'eq',
      },
    ];
  } else if (allParams.drivers) {
    initialFilter = [
      {
        field: 'offer.offerDriver.uuid',
        value: allParams.drivers,
        operator: 'eq',
      },
    ];
  }
  if (
    !!me?.role &&
    [Role.MANAGER, Role.MANAGER_ASIG, Role.MANAGER_BIG].includes(me.role)
  ) {
    initialFilter = [...initialFilter];
  }
  const {
    tableProps,
    sorter,
    tableQueryResult,
    setFilters,
    setPageSize,
    setCurrent,
  } = useTable<IUser>({
    initialSorter: [
      {
        field: 'offerNumber',
        order: 'desc',
      },
    ],
    initialPageSize: pageSizeOptions,
    initialFilter,
  });
  const edit: boolean =
    useCan({ resource: 'offer', action: 'edit' }).data?.can || false;
  const editAct: boolean =
    useCan({ resource: 'act', action: 'edit' }).data?.can || false;
  const changeUser: boolean =
    useCan({ resource: 'firms', action: 'changeUser' }).data?.can || false;
  const canDelete: boolean =
    useCan({ resource: 'offers', action: 'delete' }).data?.can || false;

  const [selectedUuid, setSelectedUuid] = useState<number | null>(null);
  const [openEditAct, setEditAct] = useState<boolean>(false);

  const [modal, contextHolder] = Modal.useModal();

  const contextMenu = (value: any, type: ClientType): MenuProps['items'] => {
    return [
      {
        label: 'Копировать название',
        key: '1',
        onClick: () => {
          navigator.clipboard.writeText(
            type === ClientType.FIRM
              ? value.offerLender.orgName
              : value.offerDriver.orgName,
          );
        },
      },
      { key: '2', type: 'divider' },
      {
        label: 'Подробнее',
        key: '3',
        disabled: true,
      },
    ];
  };

  const items = (value: any): MenuProps['items'] => {
    let cantEditRecord = true;

    if (
      me?.role &&
      [Role.ADMIN, Role.DIRECTOR, Role.SYSTEM].includes(me.role)
    ) {
      cantEditRecord = false;
    } else if (value.vama.type === VamaStatus.NEW) {
      cantEditRecord = false;
    }

    return [
      {
        label: 'История изменений',
        key: '2',
        disabled: true,
        icon: <MailOutlined />,
      },
      {
        type: 'divider',
      },
      {
        label: 'Квитанция перевозчику',
        key: '3',
        onClick: () => show('ticket', value.uuid),
        icon: <MailOutlined />,
      },
      {
        type: 'divider',
      },
      {
        label: 'Редактировать',
        key: '4',
        onClick: () => {
          openEdit('offers', value.uuid);
        },
        disabled: cantEditRecord,
        icon: <SignatureOutlined />,
      },
      {
        label: 'Дублировать',
        key: '5',
        icon: <CopyFilled />,

        onClick: async () => {
          const { status, data } = await axiosInstance.get(
            `${process.env.REACT_APP_BACKEND_URL}/api/v1/offers/dublicate/${value.uuid}`,
          );
          if (status === 200 && data) {
            openEdit('offers', data.uuid);
          }
        },
      },
      {
        type: 'divider',
      },
      {
        label: 'Инвойс заказчик',
        key: '6',
        icon: <FileTextFilled />,
        onClick: () =>
          replace(`/invoice/show/${value.uuid}&${ClientType.FIRM}`),
      },
      {
        label: 'Инвойс перевозчик',
        key: '7',
        icon: <FileTextFilled />,
        onClick: () =>
          replace(`/invoice/show/${value.uuid}&${ClientType.TRANSPORT}`),
      },
      {
        type: 'divider',
      },
      {
        label: 'Редактировать акт',
        key: '8',
        icon: <FileTextFilled />,
        disabled: !editAct,
        onClick: () => {
          setSelectedUuid(value.uuid);
          setEditAct(true);
        },
      },
      {
        label: 'Акт заказчику',
        key: '9',
        icon: <FileTextFilled />,
        disabled: !value.act,
        onClick: () => replace(`/act/show/${value.uuid}&${ClientType.FIRM}`),
      },
      {
        label: 'Акт перевозчику',
        key: '10',
        icon: <FileTextFilled />,
        disabled: !value.act,
        onClick: () =>
          replace(`/act/show/${value.uuid}&${ClientType.TRANSPORT}`),
      },
      {
        type: 'divider',
      },
      {
        label: 'Удалить заявку',
        key: '11',
        icon: <UserOutlined />,
        danger: true,
        onClick: () =>
          modal.confirm({
            onOk: () =>
              deleteOne({
                resource: 'offers',
                id: value.uuid,
                successNotification: (data: any, value, test) => {
                  return {
                    description: t('successfull'),
                    message: t('delete.textSuccess'),
                    type: 'success',
                  };
                },
              }),
            title: t('confirm'),
            content: t('offers.delete'),
          }),
        disabled: !canDelete,
      },
    ];
  };

  const contextProps = (value: any, type: ClientType) => {
    const itms = contextMenu(value, type);
    return { items: itms };
  };
  const menuProps = (value: any) => {
    const itms = items(value);
    return { items: itms };
  };

  const { options: optionUser } = useSelect<IUser[]>({
    resource: 'users/get/userList',
    optionLabel: 'label',
    optionValue: 'value',
    liveMode: 'auto',
    hasPagination: false,
  });
  const options: any[] = optionUser;

  const setMonth = (value: any) => {
    const month = dayjs(value).format('MM');
    const monthNext = dayjs(value).add(1, 'month').format('MM');
    const year = dayjs(value).format('YYYY');
    const yearNext = dayjs(value).add(1, 'month').format('YYYY');
    if (!value) {
      setFilters([
        {
          field: 'offer.createdAt',
          operator: 'gte',
          value: '',
        },
        {
          field: 'offer.createdAt',
          operator: 'lt',
          value: '',
        },
      ]);
    } else {
      setFilters([
        {
          field: 'offer.createdAt',
          operator: 'gte',
          value: `${year}-${month}-01`,
        },
        {
          field: 'offer.createdAt',
          operator: 'lt',
          value: `${yearNext}-${monthNext}-01`,
        },
      ]);
    }
  };

  const findNumber = (event: any) => {
    const { target } = event;
    const { value } = target;
    if (value) {
      setFilters([
        {
          field: 'user.uuid',
          operator: 'eq',
          value: me?.role && [Role.MANAGER].includes(me.role) ? me.uuid : '',
        },
        {
          field: 'offer.createdAt',
          operator: 'gte',
          value: '',
        },
        {
          field: 'offer.createdAt',
          operator: 'lt',
          value: '',
        },
        { field: 'offer.offerNumber', operator: 'contains', value: value },
      ]);
    } else {
      setFilters([
        ...initialFilter,
        { field: 'offer.offerNumber', operator: 'contains', value: '' },
      ]);
    }
  };

  const addOpt = [{ value: '', label: 'Показать все' }];

  return !tableProps.loading && tableProps.dataSource ? (
    <>
      <CanAccess resource="act" action="edit">
        {selectedUuid ? (
          <ModalEditAct
            onCancel={() => {
              setEditAct(false);
              setSelectedUuid(null);
            }}
            open={openEditAct}
            refetch={tableQueryResult.refetch}
            uuid={selectedUuid}
          />
        ) : (
          <></>
        )}
      </CanAccess>
      <CanAccess resource="offers" action="delete">
        {contextHolder}
      </CanAccess>
      <Suspense fallback={<Spin>Загрузка</Spin>}>
        <List
          headerProps={{
            extra: <CreateButton children="Создать заявку" type="primary" />,
          }}
        >
          <Space size={4} style={{ marginBottom: 8 }}>
            {[Firms.REAL, Firms.RCI, Firms.RCI_INVEST, Firms.RCI_PMR].map(
              (firm) => (
                <Tag color={firmColor(firm)}>{firm}</Tag>
              ),
            )}
          </Space>

          {!allParams.companies && (
            <Row gutter={[12, 12]} style={{ marginBottom: '12px' }}>
              <Col>
                <Input
                  size="small"
                  placeholder="Номер заявки"
                  allowClear
                  onChange={findNumber}
                />
              </Col>
              <Col xs={24} md={5}>
                <Select
                  showSearch
                  style={{ width: '100%' }}
                  defaultValue={me?.uuid}
                  size="small"
                  options={[...addOpt, ...options]}
                  filterOption={(input, option) =>
                    (option?.label ?? '')
                      .toUpperCase()
                      .includes(input.toLocaleUpperCase())
                  }
                  onChange={(value) => {
                    setFilters([
                      { field: 'user.uuid', operator: 'eq', value: value },
                    ]);
                  }}
                />
              </Col>
              <Col>
                <DatePicker
                  picker="month"
                  size="small"
                  defaultValue={dayjs()}
                  onChange={setMonth}
                  format={'MMMM YYYY'}
                />
              </Col>
            </Row>
          )}
          <Table
            {...tableProps}
            rowKey="uuid"
            loading={tableProps.loading}
            pagination={{
              ...tableProps.pagination,
              position: ['bottomRight'],
              showSizeChanger: true,
              pageSizeOptions: ['10', '50', '100', '200'],
              size: 'small',
            }}
            // onChange={(value) => {
            //   if (value.pageSize) {
            //     localStorage.setItem('pageSizeOptions', String(value.pageSize));
            //     setPageSize(value.pageSize);
            //   }
            //   if (value.current) {
            //     setCurrent(value.current);
            //   }
            // }}
            caption={
              <Typography.Text type="secondary" style={{ padding: '4px' }}>
                {`Всего заявок : ${tableQueryResult.data?.total}`}
              </Typography.Text>
            }
            scroll={{ x: 'max-content' }}
            size="small"
          >
            <Table.Column
              dataIndex="createdAt"
              key="createdAt"
              title={<CalendarOutlined />}
              align="center"
              render={(_, value: any) => (
                <>
                  <TextField
                    style={{ fontSize: '11px' }}
                    value={`${moment(value.createdAt).format('DD.MM')}/${moment(
                      value.dateLoad,
                    ).format('DD.MM')}`}
                  />
                </>
              )}
              sorter
            />
            <Table.Column
              dataIndex="offerNumber"
              key="offerNumber"
              fixed="left"
              title={<BookOutlined />}
              align="center"
              render={(value) => <TextField value={value} />}
              sorter
            />
            <Table.Column
              key="offerFirms"
              title=""
              width={400}
              render={(_, value: any) => (
                <Row gutter={[12, 12]}>
                  <Col xs={12}>
                    <Dropdown
                      menu={contextProps(value, ClientType.FIRM)}
                      trigger={['contextMenu']}
                    >
                      <Tag
                        style={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                          cursor: 'pointer',
                        }}
                        color={firmColor(value?.company)}
                        onClick={() =>
                          show('offers', `${value.uuid}&${ClientType.FIRM}`)
                        }
                      >
                        <div
                          style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {value?.offerLender?.orgName}
                        </div>
                      </Tag>
                    </Dropdown>
                  </Col>
                  <Col xs={12}>
                    <Dropdown
                      menu={contextProps(value, ClientType.TRANSPORT)}
                      trigger={['contextMenu']}
                    >
                      <Tag
                        style={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                          cursor: 'pointer',
                        }}
                        color={firmColor(value?.company)}
                        onClick={() =>
                          show(
                            'offers',
                            `${value.uuid}&${ClientType.TRANSPORT}`,
                          )
                        }
                      >
                        <div
                          style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {value?.offerDriver?.orgName}
                        </div>
                      </Tag>
                    </Dropdown>
                  </Col>
                </Row>
              )}
            />
            <Table.Column
              dataIndex="wayFrom"
              key="wayFrom"
              title={
                <Tooltip title="Маршрут откуда">
                  <EnvironmentOutlined />
                </Tooltip>
              }
              render={(value) => <TextField value={value} />}
            />
            <Table.Column
              dataIndex="wayTo"
              key="wayTo"
              title={
                <Tooltip title="Маршрут куда">
                  <EnvironmentOutlined />
                </Tooltip>
              }
              render={(value) => <TextField value={value} />}
            />
            <Table.Column
              dataIndex="carNumber"
              key="wayTo"
              title={<CarOutlined />}
              render={(value) => <TextField value={value} />}
            />
            <Table.Column
              dataIndex="priceFirm"
              key="priceFirm"
              title={
                <Tooltip title="Цена заказчик">
                  <FileProtectOutlined />
                </Tooltip>
              }
              render={(value, blank: any) => (
                <TextField value={`${value} ${blank.currencyFirm}`} />
              )}
            />
            <Table.Column
              dataIndex="priceDriver"
              key="priceDriver"
              title={
                <Tooltip title="Цена перевозчик">
                  <FileProtectOutlined />
                </Tooltip>
              }
              render={(value, blank: any) => (
                <TextField value={`${value} ${blank.currencyDriver}`} />
              )}
            />

            <Table.Column
              dataIndex="valutaryPay"
              key="valutaryPay"
              title={
                <Tooltip title="Валютный платеж">
                  <BankFilled />
                </Tooltip>
              }
              width={80}
              align="center"
              render={(value) => {
                switch (value) {
                  case '0':
                    return (
                      <TextField
                        value={'Отсутствует'}
                        style={{ whiteSpace: 'nowrap' }}
                      />
                    );
                  case '1':
                    return (
                      <TextField
                        value={'Полный'}
                        style={{ whiteSpace: 'nowrap' }}
                      />
                    );
                  case '2':
                    return (
                      <TextField
                        value={'Половина'}
                        style={{ whiteSpace: 'nowrap' }}
                      />
                    );
                  case '3':
                    return (
                      <TextField
                        value={'Треть'}
                        style={{ whiteSpace: 'nowrap' }}
                      />
                    );
                  case '4':
                    return (
                      <TextField
                        value={'Наличные'}
                        style={{ whiteSpace: 'nowrap' }}
                      />
                    );
                }
              }}
            />
            <Table.Column
              dataIndex="user"
              key="user"
              title="RCI"
              width={80}
              align="center"
              render={(value) =>
                value && value.pictures?.path ? (
                  <Tooltip
                    title={`${value.lastName} ${value.firstName}`}
                    placement="left"
                  >
                    <Avatar
                      style={{ width: '50px', height: '50px' }}
                      src={`${process.env.REACT_APP_BACKEND_URL}${value.pictures.path}`}
                      alt={value.lastName}
                      icon={<UserOutlined />}
                    />
                    {/* <TextField value={value.uuid} /> */}
                  </Tooltip>
                ) : (
                  <Tooltip
                    title={`${value.lastName} ${value.firstName}`}
                    placement="left"
                  >
                    <Avatar
                      style={{ width: '50px', height: '50px' }}
                      alt={value.lastName}
                      icon={<UserOutlined />}
                    />
                  </Tooltip>
                )
              }
            />

            <Table.Column
              key="action"
              title=""
              width={10}
              fixed="right"
              render={(_, value: any) => (
                <>
                  <Dropdown
                    menu={menuProps(value)}
                    arrow
                    trigger={['click']}
                    placement="bottomLeft"
                    destroyPopupOnHide
                  >
                    <Button
                      icon={<EllipsisOutlined />}
                      shape="circle"
                      type="dashed"
                    />
                  </Dropdown>
                </>
              )}
            />
          </Table>
        </List>
      </Suspense>
    </>
  ) : (
    <Spin />
  );
};
