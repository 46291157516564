import {
  CanAccess,
  CrudFilters,
  IResourceComponentsProps,
  useCan,
  useGetIdentity,
  useNavigation,
} from '@pankod/refine-core';
import {
  Avatar,
  Button,
  Col,
  CreateButton,
  DateField,
  DatePicker,
  DeleteButton,
  Dropdown,
  Form,
  Input,
  List,
  MenuItemProps,
  MenuProps,
  message,
  Row,
  Select,
  Spin,
  Switch,
  Table,
  Tag,
  TagField,
  TextField,
  Tooltip,
  Typography,
  useTable,
} from '@pankod/refine-antd';

import {
  SolutionOutlined,
  FileAddOutlined,
  UserOutlined,
  DownOutlined,
  EllipsisOutlined,
  WarningOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
  CheckCircleOutlined,
  LoadingOutlined,
  SignatureOutlined,
  FileDoneOutlined,
} from '@ant-design/icons';
import { IUser, IVama } from 'interfaces';
import { UserStatus } from '../../enums/user.status';
import React, { CSSProperties, useContext, useEffect, useState } from 'react';
import { Role } from '../../enums/role';
import { UserContext } from 'UserProvider';
import ModalAddLender from 'components/modals/addCompanies';
import { ClientType } from 'enums/client.type';
import moment from 'moment';
import { VamaStatus } from 'enums/vama.status.type';
import ModalVama from 'components/vama/modal/changeVama';
import ConfirmVama from 'components/vama/modal/confirmVama';
import { useNavigate } from '@pankod/refine-react-router-v6';
import { firmColor } from 'utility/getColor';
import ModalInfo from 'components/modals/offerInfo';
import dayjs from 'dayjs';
import { Firms } from 'enums/firms.type';
import { VamaType } from 'enums/vama.type';

export const VamaList: React.FC<IResourceComponentsProps> = () => {
  const { show } = useNavigation();
  const [open, setOpen] = React.useState<boolean>(false);
  const [selectedUuid, setSelectedUuid] = React.useState<number | null>(null);
  const userContext = useContext(UserContext);
  const me = userContext ? userContext.user : null;
  const pageSizeOptions = Number(localStorage.getItem('pageSizeOptions')) || 10;

  const initialFilter: CrudFilters = [
    {
      field: 'user.uuid',
      operator: 'eq',
      value: '',
    },
    {
      field: 'vama.vamaType',
      operator: 'eq',
      value: VamaType.EXPEDITION,
    },
    {
      field: 'vama.type',
      operator: 'in',
      value: [VamaStatus.NEW, VamaStatus.WORK],
    },
    {
      field: 'offer.createdAt',
      operator: 'gte',
      value: `${dayjs().format('YYYY')}-${dayjs().format('MM')}-01`,
    },
    {
      field: 'offer.createdAt',
      operator: 'lt',
      value: `${dayjs().add(1, 'months').format('YYYY')}-${dayjs(
        dayjs().add(1, 'months').toString(),
      ).format('MM')}-01`,
    },
  ];

  const {
    tableProps,
    sorter,
    tableQueryResult: { refetch, data },
    setFilters,
    setPageSize,
    setCurrent,
  } = useTable<IVama>({
    resource: 'vama',
    queryOptions: { retry: 5 },
    liveMode: 'auto',
    initialSorter: [
      {
        field: 'createdAt',
        order: 'desc',
      },
    ],
    initialPageSize: pageSizeOptions,
    initialFilter,
  });

  const [openModalChange, setOpenModal] = useState<boolean>(false);
  const [openModalChangeApprove, setOpenModalApprove] =
    useState<boolean>(false);

  const edit: boolean =
    useCan({ resource: 'vama_expedition', action: 'edit' }).data?.can || false;
  const confirm: boolean =
    useCan({ resource: 'vama_expedition', action: 'confirm' }).data?.can ||
    false;

  const items = (value: any): MenuProps['items'] => {
    const itemMenu = [
      {
        label: 'История изменений',
        key: '1',
        icon: <SolutionOutlined />,
        disabled: true,
      },
      {
        key: '2',
        type: 'divider',
      },
      {
        label: 'Редактировать',
        key: '3',
        icon: <SignatureOutlined />,
        onClick: () => {
          setSelectedUuid(value.uuid);
          setOpenModal(true);
        },
        disabled: !edit,
      },
    ];

    if (confirm) {
      itemMenu.push({
        key: '4',
        type: 'divider',
      });
      itemMenu.push({
        label: 'Подтвердить',
        key: '5',
        icon: <FileDoneOutlined />,
        onClick: () => {
          setSelectedUuid(value.uuid);
          setOpenModalApprove(true);
        },
        disabled: !confirm,
      });
    }

    return itemMenu;
  };

  const { list } = useNavigation();

  const menuProps = (value: any) => {
    const itms = items(value);

    return { items: itms };
  };

  const [modalContextInfo, setModalContextInfo] = useState<boolean>(false);

  const [form] = Form.useForm();

  const changeFilters = (values: any) => {
    const data = form.getFieldsValue();

    const sheckedMy = data.only_my;
    let dates: CrudFilters = [
      {
        field: 'offer.createdAt',
        operator: 'gte',
        value: data.createdAt
          ? `${dayjs(data.createdAt).format('YYYY')}-${dayjs(
              data.createdAt,
            ).format('MM')}-01`
          : '',
      },
      {
        field: 'offer.createdAt',
        operator: 'lt',
        value: data.createdAt
          ? `${dayjs(data.createdAt).add(1, 'months').format('YYYY')}-${dayjs(
              dayjs(data.createdAt).add(1, 'months').toString(),
            ).format('MM')}-01`
          : '',
      },
    ];
    if (data.offerNumber) {
      form.setFieldValue('createdAt', null);
      dates = [
        {
          field: 'offer.createdAt',
          operator: 'gte',
          value: '',
        },
        {
          field: 'offer.createdAt',
          operator: 'lt',
          value: '',
        },
      ];
    }
    let finished: CrudFilters = [
      {
        field: 'vama.type',
        operator: 'in',
        value: [VamaStatus.NEW, VamaStatus.WORK],
      },
    ];

    if (data.finished) {
      form.setFieldValue('in_work', false);
      finished = [
        {
          field: 'vama.type',
          operator: 'in',
          value: [VamaStatus.NEW, VamaStatus.WORK, VamaStatus.DONE],
        },
      ];
    } else if (data.in_work) {
      dates = [
        {
          field: 'offer.createdAt',
          operator: 'gte',
          value: '',
        },
        {
          field: 'offer.createdAt',
          operator: 'lt',
          value: '',
        },
      ];
      form.setFieldValue('createdAt', null);
      finished = [
        {
          field: 'vama.type',
          operator: 'in',
          value: [VamaStatus.WORK],
        },
      ];
    }

    setFilters([
      {
        field: 'offerLender.orgName',
        operator: 'contains',
        value: data.lender,
      },
      {
        field: 'offerNumber',
        operator: 'contains',
        value: data.offerNumber,
      },
      {
        field: 'offer.company',
        operator: 'eq',
        value: data.company,
      },
      {
        field: 'user.uuid',
        operator: 'eq',
        value: sheckedMy ? me?.uuid : '',
      },

      ...finished,
      ...dates,
    ]);
  };

  return (
    <>
      {selectedUuid && modalContextInfo && (
        <ModalInfo
          selectedUuid={Number(selectedUuid)}
          open={modalContextInfo}
          onClose={() => {
            setModalContextInfo(false);
          }}
          refetch={refetch}
        />
      )}
      <CanAccess resource="vama" action="edit">
        {!!selectedUuid && me && (
          <ModalVama
            open={openModalChange}
            close={() => {
              setOpenModal(false);
              setSelectedUuid(null);
            }}
            me={me}
            refetch={refetch}
            uuid={selectedUuid}
          />
        )}
      </CanAccess>
      <CanAccess resource="vama" action="confirm">
        {!!selectedUuid && (
          <ConfirmVama
            open={openModalChangeApprove}
            close={() => {
              setOpenModalApprove(false);
              setSelectedUuid(null);
            }}
            uuid={selectedUuid}
          />
        )}
      </CanAccess>
      <List
        headerProps={{
          extra: null,
        }}
      >
        <Form
          form={form}
          initialValues={{
            createdAt: dayjs(),
            company: '',
            lender: '',
            offerNumber: '',
            finished: false,
            in_work: false,
          }}
          layout="vertical"
        >
          <Row gutter={[6, 2]}>
            <Col xs={12} md={4}>
              <Form.Item name="lender">
                <Input
                  placeholder="Заказчик"
                  size="small"
                  onChange={changeFilters}
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col xs={12} md={3}>
              <Form.Item name="offerNumber">
                <Input
                  placeholder="Заявка"
                  size="small"
                  allowClear
                  onChange={changeFilters}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name="createdAt">
                <DatePicker
                  size="small"
                  format={'MMMM YYYY'}
                  picker="month"
                  onChange={changeFilters}
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name="company">
                <Select
                  size="small"
                  style={{ width: '200px' }}
                  onChange={changeFilters}
                >
                  <Select.Option value={''} children={'Показать все'} />
                  <Select.Option value={Firms.REAL} children={Firms.REAL} />
                  <Select.Option value={Firms.RCI} children={Firms.RCI} />
                  <Select.Option
                    value={Firms.RCI_INVEST}
                    children={Firms.RCI_INVEST}
                  />
                  <Select.Option
                    value={Firms.RCI_PMR}
                    children={Firms.RCI_PMR}
                  />
                </Select>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name="finished">
                <Switch
                  checkedChildren="Включены готовые"
                  unCheckedChildren="Готовые скрыты"
                  onChange={changeFilters}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name="in_work">
                <Switch
                  checkedChildren="В работе"
                  unCheckedChildren="Не растаможен!"
                  onChange={changeFilters}
                />
              </Form.Item>
            </Col>
            <CanAccess resource="vama_expedition" action="showAll">
              <Col>
                <Form.Item name="only_my">
                  <Switch
                    checkedChildren="Личные"
                    unCheckedChildren="Общее"
                    onChange={changeFilters}
                  />
                </Form.Item>
              </Col>
            </CanAccess>
          </Row>
        </Form>
        <Table
          {...tableProps}
          rowKey="uuid"
          pagination={{
            ...tableProps.pagination,
            position: ['bottomRight'],
            showSizeChanger: true,
            pageSizeOptions: ['10', '50', '100', '200'],
            size: 'small',
          }}
          // onChange={(value) => {
          //   if (value.pageSize) {
          //     localStorage.setItem('pageSizeOptions', String(value.pageSize));
          //     setPageSize(value.pageSize);
          //   }
          //   if (value.current) {
          //     setCurrent(value.current);
          //   }
          // }}
          caption={
            <Typography.Text type="secondary" style={{ padding: '4px' }}>
              {`Всего растаможек : ${data?.total}`}
            </Typography.Text>
          }
          scroll={{ x: 'max-content' }}
          size="small"
        >
          <Table.Column
            dataIndex="offer"
            key="offer"
            title="Заказчик"
            render={(value) => <TextField value={value.offerLender.orgName} />}
          />
          <Table.Column
            dataIndex="offer"
            key="offer"
            title="Заявка"
            align="center"
            fixed="left"
            render={(value, record: any) => (
              <Tooltip title={value.company}>
                <Tag
                  color={firmColor(value.company)}
                  style={{
                    cursor: 'pointer',
                    width: '50px',
                    textAlign: 'center',
                  }}
                  onContextMenu={(e) => {
                    e.preventDefault();
                    setSelectedUuid(record.uuid);
                    setModalContextInfo(true);
                  }}
                  onClick={() => {
                    list(`blanks/offers?uuid=${value.uuid}`);
                  }}
                >
                  {value.offerNumber}
                </Tag>
              </Tooltip>
            )}
            // defaultSortOrder={getDefaultSortOrder('offerNumber', sorter)}
            // sorter
            // filterDropdown={(props: FilterDropdownProps) => (
            //   <FilterDropdown {...props}>
            //     <Input />
            //   </FilterDropdown>
            // )}
          />
          <Table.Column
            dataIndex="datePayment"
            key="datePayment"
            align="center"
            title="Дата растаможки"
            render={(value) => (
              <TextField
                value={value ? moment(value).format('DD.MM.YYYY') : ''}
              />
            )}
          />
          <Table.Column
            dataIndex="offer"
            key="offer"
            fixed="left"
            title="Рег. номер"
            render={(value) => <TextField value={value.carNumber} />}
          />
          <Table.Column
            dataIndex="summ"
            key="summ"
            title="Сумма"
            render={(value) => (
              <TextField
                value={value ? Number(value).toLocaleString('ru-RU') : ''}
              />
            )}
          />
          <Table.Column
            dataIndex="dateZakaz"
            key="dateZakaz"
            width={200}
            title="Опл. заказчик"
            render={(_, value: IVama) => {
              const from = value.offer.firmFrom;
              const to = value.offer.firmTo;
              if (!value.datePayment) return;
              if (from === to) {
                return (
                  <TextField
                    value={moment(value.datePayment)
                      .add(from, 'days')
                      .format('DD.MM.YYYY')}
                  />
                );
              }
              return (
                <TextField
                  value={`${moment(value.datePayment)
                    .add(from, 'days')
                    .format('DD.MM.YYYY')} - ${moment(value.datePayment)
                    .add(to, 'days')
                    .format('DD.MM.YYYY')}`}
                />
              );
            }}
          />
          <Table.Column
            dataIndex="datePerevoz"
            key="datePerevoz"
            width={200}
            title="Опл. перевозчик"
            render={(_, value: IVama) => {
              const from = value.offer.driverFrom;
              const to = value.offer.driverTo;
              if (!value.datePayment) return;
              if (from === to) {
                return (
                  <TextField
                    value={moment(value.datePayment)
                      .add(from, 'days')
                      .format('DD.MM.YYYY')}
                  />
                );
              }
              return (
                <TextField
                  value={`${moment(value.datePayment)
                    .add(from, 'days')
                    .format('DD.MM.YYYY')} - ${moment(value.datePayment)
                    .add(to, 'days')
                    .format('DD.MM.YYYY')}`}
                />
              );
            }}
          />
          <Table.Column
            dataIndex="efact"
            key="efact"
            title="E-factura"
            render={(value) => <TextField value={''} />}
          />
          <Table.Column
            dataIndex="type"
            key="type"
            title="Статус"
            render={(value) => {
              switch (value) {
                case VamaStatus.NEW:
                  return (
                    <Tag color="warning" icon={<QuestionCircleOutlined />}>
                      {value}
                    </Tag>
                  );
                case VamaStatus.WORK:
                  return (
                    <Tag color="blue" icon={<LoadingOutlined />}>
                      {value}
                    </Tag>
                  );
                case VamaStatus.DONE:
                  return (
                    <Tag color="green" icon={<CheckCircleOutlined />}>
                      {value}
                    </Tag>
                  );
                default:
                  return <Tag icon={<UserOutlined />}>{value}</Tag>;
              }
            }}
          />
          <Table.Column
            dataIndex="user"
            key="user"
            align="center"
            title="RCI"
            render={(value: IUser) =>
              value && value.pictures?.path ? (
                <Tooltip
                  title={`${value.lastName} ${value.firstName}`}
                  placement="left"
                >
                  <Avatar
                    style={{ width: '50px', height: '50px' }}
                    src={`${process.env.REACT_APP_BACKEND_URL}${value.pictures.path}`}
                    alt={value.lastName}
                    icon={<UserOutlined />}
                  />
                  {/* <TextField value={value.uuid} /> */}
                </Tooltip>
              ) : (
                <Tooltip
                  title={`${value.lastName} ${value.firstName}`}
                  placement="left"
                >
                  <Avatar
                    style={{ width: '50px', height: '50px' }}
                    alt={value.lastName}
                    icon={<UserOutlined />}
                  />
                </Tooltip>
              )
            }
          />

          {/* <Table.Column
            dataIndex="comment"
            key="comment"
            title="Комментарии"
            render={(value) => <TextField value={value} />}
          />
          <Table.Column
            dataIndex="tags"
            key="tags"
            title="Теги"
            render={(value) => (
              <TextField value={JSON.parse(value).join(' ')} />
            )}
            defaultSortOrder={getDefaultSortOrder('tags', sorter)}
            sorter
          /> */}

          <Table.Column
            key="action"
            title=""
            fixed="right"
            width={20}
            render={(_, value: any) => (
              <>
                <Dropdown
                  menu={menuProps(value)}
                  arrow
                  placement="bottomLeft"
                  destroyPopupOnHide
                  trigger={['click']}
                >
                  <Button
                    icon={<EllipsisOutlined />}
                    shape="circle"
                    type="dashed"
                  />
                </Dropdown>
              </>
            )}
          />
        </Table>
      </List>
    </>
  );
};
